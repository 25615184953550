import { useState, useRef, useEffect } from "react";
import { Container, Row, Col, Button, Alert, Form } from "react-bootstrap";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { appmenus, islogin, languageName, myname, stateCode } from "./Header/States/Atom_define";
import { useQuery } from "react-query";
import { Submit_Form, VeryfyMobile, forgotPass, submit_fgOTP } from "./Query_Fetcher";
import { Link, useNavigate } from "react-router-dom";
import SetNewPassword from "./SetNewPassword";

function Fg_OTP(props) {

  const AppMenus = useRecoilValue(appmenus);
  const [otp, setOtp] = useState('');
   const [timer, setTimer] = useState(60);
  const [showResend, setShowResend] = useState(false);
  const [showForm,setShowform] = useState(true);
  const formData = new FormData();
  formData.append('otpvalue',otp);
const [otpError, setotpError]   = useState('');

  
  useEffect(() => {
    let intervalId;
    if (timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setShowResend(true);
    }
    return () => clearInterval(intervalId);
  }, [timer]);


  const  handleResend = async () => {
     setOtp('');
    const data = new FormData()
    data.append('username',props.user_data);
    const mydata = await forgotPass(data);
    if(mydata.data==='Max otp Generate')
    {
      setotpError(AppMenus[0].max_otp_try)
    }
    else
    {
      setTimer(90);
    setShowResend(false);
    }
    
};
async function otp_submit() {
  if(otp==='')
  {
    setotpError(AppMenus[0].otp)
  }
  else
  {
  
  const data = new FormData()
  data.append('username',props.user_data);
  data.append('otp',otp);
  const mydata = await submit_fgOTP(data)
  if(mydata.data==='max_attempt')
  {
    setotpError(AppMenus[0].max_otp_try)
    setOtp('')
  }
  else if (mydata.data==='otp_not_matched')
  {
      setotpError(AppMenus[0].otp_error);
      setOtp('')
  }
  else if (mydata.data==='otp_matched')
  {
          setShowform(false)
  }
  else if (mydata.data==='No_otp_exist')
  {
    setotpError('No OTP Exist, You Are Doing Something Wrong!!');
  }
  else if (mydata.data==='No user Found')
  {
    setotpError('You Should Not Be Here');
  }
}
}
console.log(showForm)
  return (
    <Container>
    {showForm?
    (
      <><Row>
      <Col sm={{ span: '8', offset: '2' }} style={{ marginTop: '15px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', padding: '20px' }}>
        <div style={{ fontWeight: 'bold' }}>{AppMenus[0].otp_sent}</div>
        <Form.Control

          type="text"
          maxLength="4"
          value={otp}
          placeholder={AppMenus[0].otp}
          onChange={(e) => { setOtp(e.target.value); setotpError(''); } }
          isInvalid={otpError !== ''}
          style={{ marginTop: '15px' }} />


        {otpError !== '' ? <Alert variant="danger" style={{ margin: '10px' }}>{otpError}</Alert> : null}
        <div><Button type="button" style={{ border: '1px solid #1e6885', marginTop: '15px', backgroundColor: '#1e6885' }} onClick={() => otp_submit()}> {AppMenus[0].submit} </Button></div>
        <div>{timer > 0 ? <div style={{ marginTop: '15px' }}>{AppMenus[0].otp_w} {timer} seconds</div> : <div style={{ marginTop: '15px', color: 'blue', cursor: 'pointer' }} onClick={handleResend}>{AppMenus[0].otp_w}</div>}</div>
      </Col>
    </Row><Row className="mt-3 justify-content-center align-items-center">
        <Col md="auto">
          <Button variant="link">Jankari update karen</Button>
        </Col>
      </Row></>
    ):<SetNewPassword username = {props.user_data}/>}
    </Container>
  );
}

export default Fg_OTP;
