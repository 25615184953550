import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { languageName } from './Header/States/Atom_define';
import { Form } from 'react-bootstrap'; // Correct import statement

function Month({ onOptionChange }) {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const lang = useRecoilValue(languageName);
  const locale = lang === 'hi' ? 'hi-IN' : 'en-US';

  const [months, setMonths] = useState(generateMonthList(currentYear, currentMonth));

  function generateMonthList(year, month) {
    const generatedMonths = [];
    for (let i = 0; i < 24; i++) {
      const date = new Date(year, month - 1);
      const monthName = date.toLocaleString(locale, { month: 'long' });
      const formattedMonth = `${month}-${year}`;
      generatedMonths.push({
        value: formattedMonth,
        label: `${monthName} ${year}`,
      });

      if (month === 1) {
        month = 12;
        year--;
      } else {
        month--;
      }
    }
    return generatedMonths;
  }

  const handleOptionChange = (event) => {
    const selectedValue = event.target.value;
    onOptionChange(selectedValue);
  };

  return (
    <Form.Select onChange={handleOptionChange}>
        <option value='all'>All</option>
      {months.map((month, index) => (
        <option key={index} value={month.value}>
          {month.label}
        </option>
      ))}
    </Form.Select>
  );
}

export default Month;
