import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useRecoilState, useRecoilValue } from 'recoil';
import { languageName, stateCode } from './States/Atom_define';
import secureLocalStorage from 'react-secure-storage';
const MyModal = () => {

  const [mylanguage, setlanguage] = useRecoilState(languageName);
  const [showModal, setShowModal] = useState(true);

  const [languageError, setLanguageError] = useState("");


  const handleCloseModal = () => {
    setShowModal(false);
  };
const handleForm = (event) => {
  event.preventDefault();

  var language = event.target.elements.language.value;
  var flag;
  flag = true;

  if(language==='emp')
  {
    setLanguageError('Please Select Language');
    flag = false
  }
  if(flag===true){

secureLocalStorage.setItem("lang",language);


    setlanguage(language);
    handleCloseModal();
  }


}
  return (
    <div>
   

      <Modal show={showModal}>
        <Modal.Header closeButton>
          <Modal.Title>GKC</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleForm}>
        <Modal.Body>

              <Form.Group controlId="formName" style={{ textAlign: "left" }}>
                <Form.Label>Select Language</Form.Label>
               <Form.Select
                  name="language"
                onChange={()=>setLanguageError("")}   
                  isInvalid={languageError!==""}
                >
                  <option value="emp">Select Language</option>
                  <option value="hi">Hindi</option>
                  <option value="en">English</option>
                  
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {languageError}
                </Form.Control.Feedback>
              </Form.Group>
         
        </Modal.Body>
        <Modal.Footer>
          <Button type='submit' variant="secondary" >
            Close
          </Button>
        </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

export default MyModal;
