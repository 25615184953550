import { useLocation } from "react-router";
import { useState } from "react";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { fetch_today_Quiz, modifyquiz, todayQuiz } from "./Query_Fetcher";
import { useRecoilState, useRecoilValue } from "recoil";
import ResultComponent from "./ResultComponent";
import {
  appmenus,
  countryName,
  islogin,
  languageName,
  querykeyval,
  stateCode,
  stateName,
} from "./Header/States/Atom_define";
import { Row, Col, Alert, Container, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";
import { useMutation, useQueryClient } from "react-query";
import QuizResultThis from "./QuizResultThis";
function StateDailyQuiz() {
  let location = useLocation();
  const path = location.pathname;
  const myurl = path.split("/");
  const [table, settable] = useState("");
  const country = useRecoilValue(countryName);
  const mystate = useRecoilValue(stateName);
  const [session, setSession] = useState("");
  const mylogin = useRecoilValue(islogin);
  const mymenus = useRecoilValue(appmenus);
  const queryClient = useQueryClient();
  const lang = useRecoilValue(languageName);
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [myquerykey, setquerykey] = useRecoilState(querykeyval);
  const toggleExplanation = (index) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };

    let mydata = QuizResultThis('state','current','all','dist');

  const thisquiz = new FormData();
  thisquiz.append("table", table);
  thisquiz.append("session", session);
  const { isSuccess, status, error, data } = useQuery(
    ["Daily-Quiz", table, session],
    () => fetch_today_Quiz("Daily-Quiz", thisquiz),
    { enabled: table !== "" && session !== "" && mylogin,staleTime:120000}
  );


  useEffect(() => {
    if (myurl[3] === "state-quiz-today") {
      settable('state');
      setSession(myurl["4"]);
    } else {
      settable('india');
      setSession(myurl[4]);
    }
  }, [path]);

  const submitmutation = useMutation(todayQuiz);
  const submitQuiz = (table,paper_id) =>{
    submitmutation
    .mutateAsync([
      "submitj_quiz",
      table,
      paper_id
    ])
    .then((mydata) => {


if(mydata==='inserted')
{
  const queryKey = ["Daily-Quiz", table, session]; // Should match the query key used in useQuery
  queryClient.removeQueries(queryKey);
 setquerykey(querykeyval+1);
  
}


    })
    .catch((error) => {
      console.error(error);
    });


  

  }
  
  const buttonMutation = useMutation(modifyquiz);
  const handleButtonClick = (
    question_id,option_id,answer_id
  ) => {
    buttonMutation
      .mutateAsync([
        "update_quiz",
       question_id,
       option_id,
       answer_id,
       table,
      ])
      .then((mydata) => {

        const updatedData = data.data.map((item) => {
          if (item.id === question_id) {
            
            return {
              ...item,
              resultid: mydata ,
              resultanswer: option_id ,
              
            };
          }
          return item;
        });


        queryClient.setQueryData(
          ["Daily-Quiz", table, session],
          { data: updatedData }
        );


      })
      .catch((error) => {
        console.error(error);
      });
  }; 

  
  return (
    <div>
      <Container className="mt-2">
        {mylogin?
      <Row>
      <Col sm={12} style={{display:'flex',justifyContent:'end'}}>
        {status==='success' && Array.isArray(data?.data)?
      <div className="position-fixed question-counter" style={{zIndex:1}} >
        
       { data.data.filter(item => item.resultanswer !== null).length+'/'+data.data.length}
        </div>:''}
        {/* Your other content within Col sm={8} */}
     </Col>
    </Row>:''}
        <Row>
      
           <Col sm='8'   >
            <div style={{textAlign:'left',fontWeight:'bold',fontSize:'1.1rem'}}>{table!=="" && table==='india'?country+' - ':mystate+' - '}  {session!=='' && session==='morning-session'?mymenus[0].today_quiz_m:mymenus[0].today_quiz_e} </div>
          {mylogin? status==='success' && Array.isArray(data?.data)?'':<Alert className="mt-4 p-4">{session==='morning-session'?mymenus[0].no_quiz_m:mymenus[0].no_quiz_e}</Alert>:''}            {mylogin ? (
              ""
            ) : (
              <Alert variant="danger" className="mt-4">
                {mymenus[0].pl_l}
                <br />
                <Link to={"/" + lang + "/register"}>
                  {mymenus[0].register}
                </Link>{" "}
                -----{" "}
                <Link to={"/" + lang + "/login"}>
                  {mymenus[0].login}
                </Link>
              </Alert>
            )}
           {status === "success" && mylogin && Array.isArray(data?.data)
              ? data?.data.map((item, index) => (
                  <>
                    {" "}
                    <div key={index}>
                      <Card
                        style={{
                          boxShadow:
                            "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                          marginBottom: "20px",
                          backgroundColor: index % 2 === 0 ? "#f2f2f2" : "",
                        }}
                      >
                        <Card.Body>
                          <Col>
                            <div style={{ textAlign: "left",fontWeight:'bold' }}>
                              {index + 1 + ". "}{" "}
                              {item.hasOwnProperty(`q_${lang}`)
                                ? item[`q_${lang}`]
                                : ""}
                            </div>
                          </Col>

                          {item.resultanswer === null ? (
                            <Col style={{ textAlign: "right" }}>
                              <small style={{ color: "#a3a0a0" }}>
                                {mymenus[0].no_answer}
                              </small>
                            </Col>
                          ) : (
                            ""
                          )}

                          <hr />
                          <div style={{ textAlign: "left" }}>
                            {item.correct ? (
                              <div
                                className="divoption"
                                style={{
                                  padding: "5px",
                                  color:
                                    item.correct === "1" ||
                                    item.resultanswer === "1"
                                      ? "white"
                                      : "black",
                                  backgroundColor:
                                    item.correct === "1"
                                      ? "#098712"
                                      : item.resultanswer === "1"
                                      ? "#c20a0a"
                                      : "white",
                                }}
                              >
                                {item.hasOwnProperty(`q_${lang}`)
                                  ? item[`1_${lang}`]
                                  : ""}
                              </div>
                            ) : (
                              <Button
                                className="divoption"
                                style={{
                                  color:
                                    item.resultanswer === "1"
                                      ? "white"
                                      : "black",
                                  backgroundColor:
                                    item.resultanswer === "1"
                                      ? "#678b9c"
                                      : "white",
                                }} onClick={() => handleButtonClick(item.id,"1",item.resultid                                )}
                              >
                                {item.hasOwnProperty(`q_${lang}`)
                                  ? item[`1_${lang}`]
                                  : ""}
                              </Button>
                            )}
                          </div>


                          <div style={{ textAlign: "left" }}>
                            {item.correct ? (
                              <div
                                className="divoption"
                                style={{
                                  padding: "5px",
                                  color:
                                    item.correct === "2" ||
                                    item.resultanswer === "2"
                                      ? "white"
                                      : "black",
                                  backgroundColor:
                                    item.correct === "2"
                                      ? "#098712"
                                      : item.resultanswer === "2"
                                      ? "#c20a0a"
                                      : "white",
                                }}
                              >
                                {item.hasOwnProperty(`q_${lang}`)
                                  ? item[`2_${lang}`]
                                  : ""}
                              </div>
                            ) : (
                              <Button
                                className="divoption"
                                style={{
                                  color:
                                    item.resultanswer === "2"
                                      ? "white"
                                      : "black",
                                  backgroundColor:
                                    item.resultanswer === "2"
                                      ? "#678b9c"
                                      : "white",
                                }}
                                onClick={() => handleButtonClick(item.id,"2",item.resultid)}
                              >
                                {item.hasOwnProperty(`q_${lang}`)
                                  ? item[`2_${lang}`]
                                  : ""} 
                              </Button>
                            )}
                          </div>


                          <div style={{ textAlign: "left" }}>
                            {item.correct ? (
                              <div
                                className="divoption"
                                style={{
                                  padding: "5px",
                                  color:
                                    item.correct === "3" ||
                                    item.resultanswer === "3"
                                      ? "white"
                                      : "black",
                                  backgroundColor:
                                    item.correct === "3"
                                      ? "#098712"
                                      : item.resultanswer === "3"
                                      ? "#c20a0a"
                                      : "white",
                                }}
                              >
                                {item.hasOwnProperty(`q_${lang}`)
                                  ? item[`3_${lang}`]
                                  : ""}
                              </div>
                            ) : (
                              <Button
                                className="divoption"
                                style={{
                                  color:
                                    item.resultanswer === "3"
                                      ? "white"
                                      : "black",
                                  backgroundColor:
                                    item.resultanswer === "3"
                                      ? "#678b9c"
                                      : "white",
                                }}
                                onClick={() => handleButtonClick(item.id,"3",item.resultid)}
                              >
                                {item.hasOwnProperty(`q_${lang}`)
                                  ? item[`3_${lang}`]
                                  : ""} 
                              </Button>
                            )}
                          </div>



                          <div style={{ textAlign: "left" }}>
                            {item.correct ? (
                              <div
                                className="divoption"
                                style={{
                                  padding: "5px",
                                  color:
                                    item.correct === "4" ||
                                    item.resultanswer === "4"
                                      ? "white"
                                      : "black",
                                  backgroundColor:
                                    item.correct === "4"
                                      ? "#098712"
                                      : item.resultanswer === "4"
                                      ? "#c20a0a"
                                      : "white",
                                }}
                              >
                                {item.hasOwnProperty(`q_${lang}`)
                                  ? item[`4_${lang}`]
                                  : ""}
                              </div>
                            ) : (
                              <Button
                                className="divoption"
                                style={{
                                  color:
                                    item.resultanswer === "4"
                                      ? "white"
                                      : "black",
                                  backgroundColor:
                                    item.resultanswer === "4"
                                      ? "#678b9c"
                                      : "white",
                                }}
                                onClick={() => handleButtonClick(item.id,"4",item.resultid)}
                              >
                                {item.hasOwnProperty(`q_${lang}`)
                                  ? item[`4_${lang}`]
                                  : ""} 
                              </Button>
                            )}
                          </div>



                          {item.correct && (
                            <Card.Text>
                              <h6
                                style={{
                                  marginTop: "15px",
                                  color: "blue",
                                  textDecoration: "underline",
                                  textAlign: "left",
                                  cursor: "pointer",
                                }}
                                onClick={() => toggleExplanation(index)}
                              >
                                {mymenus[0].ld_m}
                              </h6>
                            </Card.Text>
                          )}

                          {expandedIndex === index && (
                            <Card.Text
                              style={{
                                textAlign: "left",
                                border: "1px solid black",
                                padding: "15px",
                                borderRadius: "5px",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(item.expl),
                              }}
                            ></Card.Text>
                          )}
                        </Card.Body>
                      </Card>
                 
                    </div>
                  </>
                ))
              : ""}
            {status==='success' && Array.isArray(data?.data) && data.data.some(item => !item.hasOwnProperty('correct') )? <Button className="m-3" onClick={()=>submitQuiz(table,data.data[0].paper_id)}>Submit & See Result</Button>:''}
          </Col>
          <Col  sm='4' >
      {table!==""?table==='india'?<ResultComponent  table_name = {'india'} />:
<ResultComponent table_name = {table} /> :''}

          </Col> 
          
        </Row>
      </Container>
    </div>
  );
}
export default StateDailyQuiz;
