import axios from "axios";
const fetchstate = (key,ste,lan) =>
{
 const url = 'https://clsheilkmp.gkcmp.in/GKC/header.php';
 const mydata = axios.get(url,{params : {ste:ste,lan:lan}}
  ) .catch(error => {
    console.log(error);
  });
return mydata;
}
const fetchstatedata = (key,ste,lan) =>
{
 const url = 'https://clsheilkmp.gkcmp.in/GKC/header.php';
 const mydata = axios.get(url,{params : {state:ste,lan:lan}}).catch(error => {
  console.log(error);
});
return mydata;
}
const fetchindiadata = (key,lan) =>
{
 const url = 'https://clsheilkmp.gkcmp.in/GKC/header.php';
 const mydata = axios.get(url,{params : {country_language:lan}}).catch(error => {
  console.log(error);
});
return mydata;
}
const fetchAppdata = (key,lan) =>
{
 const url = 'https://clsheilkmp.gkcmp.in/GKC/header.php';
 const mydata = axios.get(url,{params : {app_lan:lan}}).catch(error => {
  console.log(error);
});
return mydata;
}
const fetchBasic_app_data = (key,lan,state) =>
{
 const url = 'https://clsheilkmp.gkcmp.in/GKC/header.php';
 const mydata = axios.get(url,{params : {lan:state}}).catch(error => {
  console.log(error);
});
return mydata;
}

const fetchLanguageList = (key,llst) =>
{
 const url = 'https://clsheilkmp.gkcmp.in/GKC/header.php';
 const mydata = axios.get(url,{params : {llst:llst}}).catch(error => {
  console.log(error);
});
return mydata;
}

const fetchstateprefrence = (key,slst) =>
{
 const url = 'https://clsheilkmp.gkcmp.in/GKC/header.php';
 const mydata = axios.get(url,{params : {slst:slst}}).catch(error => {
  console.log(error);
});
return mydata;
}



   const fetchDistrictList   = (key,lan,state) =>
   {
       const url = 'https://clsheilkmp.gkcmp.in/GKC/header.php';
       const mydata = axios.get(url,{params : {district_list:'district',lang:lan}}).catch(error => {
        console.log(error);
      });
      return mydata;
      } 

      const Submit_Form = (key, data) => {
        const url = 'https://clsheilkmp.gkcmp.in/GKC/gen_jwt.php';
        const mydata = axios.post(url, data,{withCredentials:true} ).catch(error => {
          console.log(error);
        });

        return mydata;
      }

      const VeryfyMobile = (key,mobieveryfy)=> {
        const url = 'https://clsheilkmp.gkcmp.in/GKC/regi.php';
        const mydata = axios.post(url,mobieveryfy ).catch(error => {
          console.log(error);
        });
        return mydata;
      }



      const Checkfunction = (key,language)=> {
        const url = 'https://clsheilkmp.gkcmp.in/GKC/check.php';
        const mydata =  axios.post(url,{language},{withCredentials:true} ).catch(error => {
          console.log(error);
        });
        return mydata;
      }
      const LogOut = (key)=> {
        const url = 'https://clsheilkmp.gkcmp.in/GKC/logout.php';
        const mydata =  axios.post(url,key,{withCredentials:true} ).catch(error => {
          console.log(error);
        });
        return mydata;
      }
      
      const Login_Form = (key,cred)=> {
        const url = 'https://clsheilkmp.gkcmp.in/GKC/login.php';
        const mydata =  axios.post(url,cred,{withCredentials:true} ).catch(error => {
          console.log(error);
        });
        return mydata;
      }
      const State_Content = (key,cred)=> {
        const url = 'https://clsheilkmp.gkcmp.in/GKC/state_content.php';
        const mydata =  axios.post(url,cred,{withCredentials:true} ).catch(error => {
          console.log(error);
        });
        return mydata;
      }
  
  const modifyNotes = async (key) => {
  const formData = new FormData();
  formData.append('content_url', key[1]);
  formData.append('st_code', key[2]);
  formData.append('content_id', key[3]);
  formData.append('notes_id', key[4]);
  formData.append('operation', key[5]);
   
      const url = 'https://clsheilkmp.gkcmp.in/GKC/update_notes.php';
      
      try {
        const response = await axios.post(url, formData, { withCredentials: true });
        return response.data; // Assuming the response data contains the result of the operation
      } catch (error) {
        console.log(error);
        throw error; // Rethrow the error to be handled by React Query
      }
    };




    const current_up_del = async (key) => {
      const formData = new FormData();
      formData.append('content_url', key[1]);
      formData.append('state', key[2]);
      formData.append('content_id', key[3]);
      formData.append('notes_id', key[4]);
      formData.append('operation', key[5]);
       
          const url = 'https://clsheilkmp.gkcmp.in/GKC/update_current_notes.php';
          
          try {
            const response = await axios.post(url, formData, { withCredentials: true });
            return response.data; // Assuming the response data contains the result of the operation
          } catch (error) {
            console.log(error);
            throw error; // Rethrow the error to be handled by React Query
          }
        };




    const modifycurrent = async (key) => {

     
          const url = 'https://clsheilkmp.gkcmp.in/GKC/update_current_notes.php';
          
          try {
            const response = await axios.post(url, key[1], { withCredentials: true });
            return response.data; // Assuming the response data contains the result of the operation
          } catch (error) {
            console.log(error);
            throw error; // Rethrow the error to be handled by React Query
          }
        };


    const fetchMyStateNotes = (key,cred)=> {
      const url = 'https://clsheilkmp.gkcmp.in/GKC/state_wise_notes.php';
      const mydata =  axios.post(url,cred,{withCredentials:true} ).catch(error => {
        console.log(error);
      });
      return mydata;
    }

    const fetchCurrentNotes = (key,cred)=> {
      const url = 'https://clsheilkmp.gkcmp.in/GKC/current_notes.php';
      const mydata =  axios.post(url,cred,{withCredentials:true} ).catch(error => {
        console.log(error);
      });
      return mydata;
    }
    const Current_Content = (key,cred)=> {
      const url = 'https://clsheilkmp.gkcmp.in/GKC/current.php';
      const mydata =  axios.post(url,cred,{withCredentials:true} ).catch(error => {
        console.log(error);
      });
      return mydata;
    }


    const fetch_today_Quiz = (key,cred)=> {
      const url = 'https://clsheilkmp.gkcmp.in/GKC/today_quiz.php';
      const mydata =  axios.post(url,cred,{withCredentials:true} ).catch(error => {
        console.log(error);
      });
      return mydata;
    }

    const modifyquiz = async (key) => {
      const quizoption = new FormData();
      quizoption.append('update_quiz',key[0]);
      quizoption.append('quesion_id',key[1]);
      quizoption.append('option_id',key[2]);
      quizoption.append('answer_id',key[3]);
      quizoption.append('table',key[4]);


      const url = 'https://clsheilkmp.gkcmp.in/GKC/today_quiz_option.php';
      
      try {
        const response = await axios.post(url, quizoption, { withCredentials: true });
        return response.data; // Assuming the response data contains the result of the operation
      } catch (error) {
        console.log(error);
        throw error; // Rethrow the error to be handled by React Query
      }
    };
    

    const todayQuiz = async (key) => {
      const quizoption = new FormData();
      console.log(key);
      quizoption.append('table_name',key[1]);
      quizoption.append('paper_id',key[2]);
      const url = 'https://clsheilkmp.gkcmp.in/GKC/submit_quiz.php';
      
      try {
        const response = await axios.post(url, quizoption, { withCredentials: true });
        return response.data; // Assuming the response data contains the result of the operation
      } catch (error) {
        console.log(error);
        throw error; // Rethrow the error to be handled by React Query
      }
    };

     const quizResulttable = (key,cred)=> {
      const url = 'https://clsheilkmp.gkcmp.in/GKC/quiz_result_time.php';
      const mydata =  axios.post(url,cred,{withCredentials:true} ).catch(error => {
        console.log(error);
      });
      return mydata;
    }

    const forgotPass = (data)=> {
      const url = 'https://clsheilkmp.gkcmp.in/GKC/fgpass.php';
      const mydata = axios.post(url,data ).catch(error => {
        console.log(error);
      });
      return mydata;
    }
    const submit_fgOTP = (data)=> {
      const url = 'https://clsheilkmp.gkcmp.in/GKC/fgpass_otp_submit.php';
      const mydata = axios.post(url,data ).catch(error => {
        console.log(error);
      });
      return mydata;
    }
    const setNewpassfun = (data)=> {
      const url = 'https://clsheilkmp.gkcmp.in/GKC/setNewpass.php';
      const mydata = axios.post(url,data ).catch(error => {
        console.log(error);
      });
      return mydata;
    }



    const fetch_key_notes = async (key,data,lang) => {
      
      const quizoption = new FormData();
      quizoption.append('type',data);
      quizoption.append('lang',lang);
      const url = 'https://clsheilkmp.gkcmp.in/GKC/key_points.php';
      
      try {
        const response = await axios.post(url, quizoption);
        return response.data; // Assuming the response data contains the result of the operation
      } catch (error) {
        console.log(error);
        throw error; // Rethrow the error to be handled by React Query
      }
    };



    
export {fetch_key_notes,setNewpassfun,submit_fgOTP,forgotPass,fetchstateprefrence, todayQuiz, quizResulttable, modifyquiz,fetch_today_Quiz,current_up_del,modifycurrent,Current_Content,fetchCurrentNotes,fetchMyStateNotes, LogOut,State_Content, modifyNotes,fetchDistrictList,fetchstate,fetchstatedata, Checkfunction,fetchindiadata,fetchAppdata,fetchBasic_app_data,fetchLanguageList,Submit_Form,VeryfyMobile,Login_Form};