import { useEffect, useState } from "react";
import { Form, Button, Col, Container, Row, Alert, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import {  useRecoilValue, useSetRecoilState } from "recoil";
import axios from "axios";
import {
  appmenus,
  islogin,
  languageName,
  myname,
  stateCode,
} from "./Header/States/Atom_define";
import { useQuery } from "react-query";
import {
  fetchDistrictList,
 } from "./Query_Fetcher";
import { ArrowBarLeft, Eye, EyeSlash, Handbag } from "react-bootstrap-icons";
function Registration() {
  const language = useRecoilValue(languageName);
  const st_code = useRecoilValue(stateCode);
  const AppMenus = useRecoilValue(appmenus);
  const [issubmitted, setsubmitted] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [mobile_not_aval,setmobile_not_avail] = useState('');
  const [email_not_aval,setemail_not_avail] = useState('');
  const [otp, setotp] = useState("");
  const [otpError, setotpError] = useState("");
  const [timer, setTimer] = useState(60);
  const newErrors = [];
  const submitData = new FormData();
  const  setlogin = useSetRecoilState(islogin);
  const setmyname = useSetRecoilState(myname);
  const [formData, setFormData] = useState({
    name: "",
    district: "",
    mobile: "",
    email: "",
    password: "",
    confirmpassword: "",
  });
  const navigate = useNavigate();
  const mobilevery = new FormData();
  mobilevery.append("verifymobile", formData.mobile);
  mobilevery.append("verifyemail", formData.email);
  mobilevery.append("name", formData.name);
  const [errors, setErrors] = useState([]);
  const [isprocess,setprocess] = useState(false);
  //resend otp
  const handleResend = () => {
    setTimer(2);
    setotp("");

    VeryfyMobile(mobilevery);

  };
  ////Timer for Resend
  useEffect(() => {
    if (issubmitted) {
      let intervalId;
      if (timer > 0) {
        intervalId = setInterval(() => {
          setTimer((prevTimer) => prevTimer - 1);
        }, 1000);
      } else {
      }
      return () => clearInterval(intervalId);
    }
  }, [timer, issubmitted]);

  //Send OTP


  const VeryfyMobile = async (data_to_send) => {
    const url = 'https://clsheilkmp.gkcmp.in/GKC/regi.php';
    setprocess(true);
    axios.post(url, data_to_send)
      .then(response => {
              
       if (response.data.error === "mobile") {
          newErrors.push("Please enter a valid mobile number");
          setErrors(newErrors);
          setmobile_not_avail(AppMenus[0].not_aval);
        
        } else if (response.data.error === "email") {
          newErrors.push("Please enter a valid email");
          setemail_not_avail(AppMenus[0].not_aval);
          setErrors(newErrors);
     
        } else if (response.data.error === "both") {
          newErrors.push("Please enter a valid mobile number");
          newErrors.push("Please enter a valid email");
          setemail_not_avail(AppMenus[0].not_aval);
          setmobile_not_avail(AppMenus[0].not_aval);
          setErrors(newErrors);

        } else if (response.data === "otp_exceeded") {
          setErrors([]);
          setotpError(AppMenus[0].max_otp_try);
          window.scrollTo({
            top: 0,
            behavior: 'auto' // Optional: Use "auto" for instant scrolling without animation
          })
        } else {
          setsubmitted(true);
          setotpError('');
          setErrors([]);
                  
        }
      
      })
      .catch(error => {
        console.log(error);
        throw error; // Throw the error again to propagate it to the caller
      })
      .finally(() => {
          setprocess(false);
      });
  };
  


  const Submit_Form = async (data) => {
    const url = 'https://clsheilkmp.gkcmp.in/GKC/gen_jwt.php';
    return axios.post(url, data, { withCredentials: true })
      .then(response => {
        if (response.data === 1) {
          setlogin(true);
          setmyname(formData.name);
          navigate("/" + language + "/", { msg: { data: "successfull login" }  });
        }
        if(response.data==='Max-Attempt')
        {
          setotpError(AppMenus[0].max_otp_try);
        }
        if (response.data === "wrong_otp") {
          setotpError(AppMenus[0].otp_error);
        }
      })
      .catch(error => {
        // Handle errors here
        console.log('Error occurred:', error);
        throw error; // Throw the error again to propagate it to the caller
      })
      .finally(() => {
        // This block will be executed regardless of the promise's outcome
        // Perform any cleanup tasks here
        
      });
  };


  function submit_otp(e) {
    console.log(formData.name);
    submitData.append("name", formData.name);
    submitData.append("mobile", formData.mobile);
    submitData.append("email", formData.email);
    submitData.append("password", formData.password);
    submitData.append("state", formData.state);
    submitData.append("district", formData.district);
    submitData.append("otpvalue", otp);
    Submit_Form(submitData);
  }


  const {
    isloading: districtloading,
    status: districtstatus,
    error: districterror,
    data: districtdata,
  } = useQuery({
    queryKey: ["DistrictList", language],
    staleTime: Infinity,
    queryFn: () => fetchDistrictList("DistrictList", language),
  });
  let district_option;
  if (districtstatus === "success") {
    district_option = districtdata.data.map((mydata) => (
      <option key={mydata.id} value={mydata.id}>
        {mydata[language]}
      </option>
    ));
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setmobile_not_avail('');
    setemail_not_avail('');
    if (
      formData.name.trim() === "" ||
      /\d/.test(formData.name) ||
      /[^\w\s]/.test(formData.name)
    ) {
      newErrors.push("Please enter a valid name");
    }
    else{
      mobilevery.set("name", formData.name);
    }

    if (!formData.district) {
      newErrors.push("Please select a district");
    }

    if (
      formData.mobile.trim() === "" ||
      isNaN(formData.mobile) ||
      formData.mobile.trim().length !== 10
    ) {
      newErrors.push("Please enter a valid mobile number");
    } else {
      mobilevery.set("verifymobile", formData.mobile);
    }

    if (formData.email.trim() === "" || !/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.push("Please enter a valid email");
    } else {
      mobilevery.set("verifyemail", formData.email);
    }

    if (
      !formData.password.trim() ||
      !/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,}$/.test(
        formData.password
      )
    ) {
      newErrors.push("Please enter a valid password");
    }

    if (
      !formData.confirmpassword.trim() ||
      formData.confirmpassword !== formData.password
    ) {
      newErrors.push("Please enter a valid confirmpassword");
    }

    if (newErrors.length === 0) {
     VeryfyMobile(mobilevery);
    } else {
      setErrors(newErrors);
    }
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    if (errors.length > 0) {
      setErrors([]);
    }
  };
  const handlePasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
         <Row className="m-2 justify-content-center">
        <Col sm={4}>
          {otpError !== "" ? <Alert variant="danger">{otpError}</Alert> : ""}
        </Col>
      </Row>
      {!issubmitted ? (
        <Container className="mt-4 mb-4">
          <h4>{AppMenus[0].register}</h4>
          <Col
            md={6}
            sm={10}
            xs={10}
            className="mx-auto p-4 "
            style={{ border: "2px solid", backgroundColor: "#f2f6fc" }}
          >
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formName" style={{ textAlign: "left" }}>
                <Form.Label>{AppMenus[0].name}</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder={AppMenus[0].namep}
                  autoFocus={true}
                  value={formData.name}
                  onChange={handleInputChange}
                  isInvalid={errors.includes("Please enter a valid name")}
                />
                <Form.Control.Feedback
                  type="invalid"
                  style={{ textAlign: "left" }}
                >
                  {AppMenus[0].name_error}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                controlId="formDistrict"
                style={{ textAlign: "left" }}
                className="mt-3"
              >
                <Form.Label>{AppMenus[0].district}</Form.Label>
                <Form.Select
                  name="district"
                  value={formData.district}
                  onChange={handleInputChange}
                  isInvalid={errors.includes("Please select a district")}
                >
                  <option value="">{AppMenus[0].district}</option>
                  {district_option}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {AppMenus[0].district_error}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                controlId="formMobile"
                style={{ textAlign: "left" }}
                className="mt-3"
              >
                <Form.Label>{AppMenus[0].mobile}</Form.Label>
                <Form.Control
                  type="number"
                  name="mobile"
                  placeholder={AppMenus[0].mobilep}
                  value={formData.mobile}
                  onChange={handleInputChange}
                  isInvalid={errors.includes(
                    "Please enter a valid mobile number"
                  )}
                />

                <Form.Control.Feedback type="invalid">
                 { mobile_not_aval===''? AppMenus[0].mobile_error:
                   AppMenus[0].mobile+' '+mobile_not_aval
                 }
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                controlId="formEmail"
                style={{ textAlign: "left" }}
                className="mt-3"
              >
                <Form.Label>{AppMenus[0].email}</Form.Label>
                <Form.Control
                  type="text"
                  name="email"
                  placeholder={AppMenus[0].email}
                  value={formData.email}
                  onChange={handleInputChange}
                  isInvalid={errors.includes("Please enter a valid email")}
                />
                <Form.Control.Feedback type="invalid">
                { email_not_aval===''? AppMenus[0].email_error:
                   AppMenus[0].email+' '+email_not_aval
                 } 

                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                controlId="formPassword"
                style={{ textAlign: "left" }}
                className="mt-3"
              >
                <Form.Label>{AppMenus[0].password}</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  placeholder={AppMenus[0].passp}
                  value={formData.password}
                  onChange={handleInputChange}
                  isInvalid={errors.includes("Please enter a valid password")}
                />
                <Form.Control.Feedback type="invalid">
                  {AppMenus[0].password_error}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                controlId="formConfirmPassword"
                style={{ textAlign: "left" }}
                className="mt-3"
              >
                <Form.Label>{AppMenus[0].cpass}</Form.Label>
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  name="confirmpassword"
                  placeholder={AppMenus[0].cpassp}
                  value={formData.confirmpassword}
                  onChange={handleInputChange}
                  isInvalid={errors.includes(
                    "Please enter a valid confirmpassword"
                  )}
                />{" "}
                <Link onClick={handlePasswordToggle}>
                  {showPassword ? <Eye color="black"/> : <EyeSlash color="black"/>}
                </Link>
                <Form.Control.Feedback type="invalid">
                  {AppMenus[0].cpassword_error}
                </Form.Control.Feedback>
              </Form.Group>

              {isprocess? <Spinner style={{color:'#468094'}}/>:       
              <Button className="mt-2" style={{backgroundColor:'#468094',border:'1px solid #468094'}}  type="submit">
               {} {AppMenus[0].register}
              </Button>}
            </Form>
            <div className="text-center mt-3">
              <span>{AppMenus[0].oluser} </span>
              <Link to={"/" + language + "/login"}>
                {AppMenus[0].login}
              </Link>
            </div>
          </Col>
        </Container>
      ) : (
        <Container className="mt-4 mb-4">
          <h5>{AppMenus[0].otp_sent}</h5>
          <Col
            md={6}
            sm={10}
            xs={10}
            className="mx-auto p-4 "
            style={{ border: "2px solid", backgroundColor: "#f2f6fc" }}
          >
            <Form.Group
              controlId="formotp"
              style={{ textAlign: "left" }}
              className="mt-3"
            >
              <Form.Label>{AppMenus[0].otp}</Form.Label>
              <Form.Control
                type="number"
                name="otp"
                placeholder={AppMenus[0].otp}
                value={otp}
                onChange={(event) => {setotp(event.target.value);setotpError('')}}
                isInvalid={otpError!==''}
              />
              <Form.Control.Feedback type="invalid">
                
              </Form.Control.Feedback>
            </Form.Group>
           <div className="mt-3"> 
           {isprocess?<Spinner style={{color:'#0d7091',height:'50px'}} />:
           <Button style={{backgroundColor:'#468094',border:'1px solid #468094'}} disabled={otp.length < 4} onClick={() => submit_otp()}>
              {AppMenus[0].submit}
            </Button>}
            </div>            

          </Col>

          <Row className="mt-3 justify-content-center align-items-center">
            <Col >
              {timer > 0 ? (
                <Button style={{backgroundColor:'#468094',border:'1px solid #468094'}} variant="primary" disabled>
                  {AppMenus[0].otp_w} {timer} {AppMenus[0].second} 
                </Button>
              ) : (
                <Button  style={{backgroundColor:'#468094',border:'1px solid #468094'}} variant="primary" onClick={handleResend}>
                  {AppMenus[0].orsnd}
                </Button>
              )}
            </Col>
          </Row>
          <Row className="mt-3 justify-content-center align-items-center">
            <Col md="auto">
              <Button style={{backgroundColor:'#468094',border:'1px solid #468094'}} onClick={()=>{setsubmitted('');setotp('');setErrors('');}}><ArrowBarLeft size={30} /> {'  '+AppMenus[0].go_back}</Button>
            </Col>
          </Row>
        </Container>
      )}{" "}
 
    </>
  );
}

export default Registration;
