import { useState } from "react";
import { Form, Row, Col, Alert, Button, Spinner } from "react-bootstrap";
import { appmenus, languageName } from "./Header/States/Atom_define";
import { useRecoilValue } from "recoil";
import { setNewpassfun } from "./Query_Fetcher";
import { useNavigate } from "react-router";

function SetNewPassword(props) {
  const [newpassword, SetPassword] = useState("");
  const [newpassword2, SetNewPassword2] = useState("");
  const [PasswordError, SetPasswordError] = useState("");
  const mymenus = useRecoilValue(appmenus);
  const [isprocess,setprocess] = useState('');
  const mylanguage = useRecoilValue(languageName);
  const navigate = useNavigate();
  //console.log(props.username)
 async function otp_submit() {
    const regex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    var flag = true;
    if (newpassword.trim === "") {
      SetPasswordError(mymenus[0].password_error);
      flag = false;
    } else if (newpassword !== newpassword2) {
      SetPasswordError(mymenus[0].cpassword_error);
      flag = false;
    } else if (!regex.test(newpassword)) {
      SetPasswordError(mymenus[0].password_error);
      flag = false;
    }
    if (flag === true) {
        setprocess(true);
        const data = new FormData()
        data.append('username',props.username);
        data.append('pass',newpassword);
        const mydata = await setNewpassfun(data);
        setprocess(false)
        if(mydata.data===0)
        {
            SetPasswordError(mymenus[0].differ_pass)
        }
        if(mydata.data===1)
        {
            SetPasswordError('Your SESSION is Expired!!');
        }
        if(mydata.data===5)
        {
            alert(mymenus[0].succ_comp);
            navigate('/'+mylanguage+'/login/');

        }
    }
  }
  return (
    <div>
      <Row>
        <Col
          sm={{ span: "8", offset: "2" }}
          style={{
            marginTop: "15px",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            padding: "20px",
          }}
        >
          <div style={{ fontWeight: "bold",textAlign:'left' }}>{mymenus[0].set_np}</div>
          <Form>
          <Form.Label style={{ textAlign: "left" }}>
            {mymenus[0].password}
          </Form.Label>
          <Form.Control
            type="password"
            maxLength="20"
            value={newpassword}
            onChange={(e) => {
              SetPassword(e.target.value);
              SetPasswordError("");
            }}
            isInvalid={PasswordError !== ""}
            
          />
          <Form.Label style={{ textAlign: "left" }}>
            {mymenus[0].cpassp}
          </Form.Label>
          <Form.Control
            type="password"
            maxLength="20"
            value={newpassword2}
            onChange={(e) => {
              SetNewPassword2(e.target.value);
              SetPasswordError("");
            }}
            isInvalid={PasswordError !== ""}
            
          />
        </Form>
          {PasswordError !== "" ? (
            <Alert variant="danger" style={{ margin: "10px" }}>
              {PasswordError}
            </Alert>
          ) : null}
          <div>
         
         {isprocess?<Spinner style={{marginTop:'15px',color:'#0d7091'}}/>:
            <Button
              type="button"
              style={{
                border: "1px solid #1e6885",
                marginTop: "15px",
                backgroundColor: "#1e6885",
              }}
              onClick={() => otp_submit()}
            >
              {" "}
              {mymenus[0].submit}{" "}
            </Button>
}
          </div>
        </Col>
      </Row>
    </div>
  );
}
export default SetNewPassword;
