import axios from "axios";
import { useEffect, useState } from "react";
import { Container,Form,Col, Row,Button,Spinner } from "react-bootstrap";
import { Eye,EyeSlash } from "react-bootstrap-icons";
import { useRecoilState, useRecoilValue } from "recoil";
import { appmenus, languageName, myname } from "./Header/States/Atom_define";
import { useQuery } from "react-query";
import { fetchDistrictList } from "./Query_Fetcher";
import MyCircle from "./Circle";
import { useNavigate } from "react-router";
function MyProfile()
{
    const language = useRecoilValue(languageName);
    const AppMenus = useRecoilValue(appmenus)
    const [isname,setmyname ] = useRecoilState(myname);
    const [profileLoading,setprofileloading] = useState(true)
    const [isprocess,setprocess] = useState(false)
    const [enterPassword,setEnterpassword ] = useState(false)
    const[name,setname] = useState('');
    const [password,SetPassword] = useState('')
    const [password_error,SetPasswordError] = useState('')
    const[district,setdistrict] = useState('')
    const[mobile,setmobile] = useState('')
    const[email,setemail] = useState('')
    const [nameError,setNameError] = useState('')
    const [districtError,setDistrictError] = useState('')
    const [mobileError,setMobileError] = useState('')
    const [emailError,setEmailError] = useState('')
    const navigate = useNavigate();
    const {
        isloading: districtloading,
        status: districtstatus,
        error: districterror,
        data: districtdata,
      } = useQuery({
        queryKey: ["DistrictList", language],
        staleTime: Infinity,
        queryFn: () => fetchDistrictList("DistrictList", language),
      });
      let district_option;
      if (districtstatus === "success") {
        district_option = districtdata.data.map((mydata) => (
          <option key={mydata.id} value={mydata.id}>
            {mydata[language]}
          </option>
        ));
      }

      async function final_submit() {
        const url = 'https://clsheilkmp.gkcmp.in/GKC/my_profile.php';
        const queryname = new FormData();
        queryname.append('form_submit', 'with_data');
        queryname.append('mobile', mobile);
        queryname.append('email', email);
        queryname.append('name', name);
        queryname.append('district', district);
        queryname.append('password', password);
          try {
            const response = await axios.post(url, queryname, { withCredentials: true });
            return response.data; // Return the data
        } catch (error) {
            console.log(error);
            return null;
        }
    }




      async function fetchData() {
          const url = 'https://clsheilkmp.gkcmp.in/GKC/my_profile.php';
          const queryname = new FormData();
          queryname.append('fetch_profile', 'with_data');
          queryname.append('lang', language);
            try {
              const response = await axios.post(url, queryname, { withCredentials: true });
              return response.data; // Return the data
          } catch (error) {
              console.log(error);
              return null;
          }
      }

      async function testData() {
        const url = 'https://clsheilkmp.gkcmp.in/GKC/my_profile.php';
        const queryname = new FormData();
        queryname.append('test_data', 'with_data');
        queryname.append('mobile', mobile);
        queryname.append('email', email);

          try {
            const response = await axios.post(url, queryname, { withCredentials: true });
            return response.data; // Return the data
        } catch (error) {
            console.log(error);
            return null;
        }
    }

  
      useEffect(() => {
          const fetchDataAsync = async () => {
              const result = await fetchData();
              if(result!==null)
              {
                setname(result.name)
                setdistrict(result.district)
                setmobile(result.mobile)
                setemail(result.email)
                setprofileloading(false)
              }
          };
  
          fetchDataAsync(); // Call the async function within useEffect
      }, []);
      
      const final_submission = async (event) => {
        event.preventDefault();
        if(password==='')
        {
            SetPasswordError(AppMenus[0].password);
        }
        else
        {
            setprocess(true)
            const result = await final_submit();
            setprocess(false)
            if(result===0)
            {
                   SetPasswordError(AppMenus[0].wrng_pass) 
            }
            if(result===1)
            {
                alert(AppMenus[0].succ_comp)
                if(isname!==name)
                {
                    setmyname(name)
                }

                navigate('/'+language+'/');
            }
        }
      }
    const handleSubmit = (event) => {
        event.preventDefault();
        var flag = false;
        const regex = /^[a-zA-Z\s]*$/;
        if(name==='')
        {
                setNameError(AppMenus[0].name_error)
                flag = true;
        }
        else if (!regex.test(name)) {
            setNameError(AppMenus[0].name_error)
            flag = true;
        } else {
            setNameError('');
        }
        if(district==='')
        {
            setDistrictError('district_Error');
            flag = true;
        }
        const myregex = /^\d{10}$/;
        if (!myregex.test(mobile)) {
            setMobileError(AppMenus[0].mobile_error);
            flag = true;
        } else {
            setMobileError('');
        }

        const chemail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (!chemail.test(email)) {
            setEmailError(AppMenus[0].email_error);
            flag = true
        } else {
            setEmailError('');
        }
        if(flag===false)
        {
            setprocess(true)
            const mobil_email = async () => {
                const result = await testData();
                if(result!==null)
                {
                   setprocess(false)
                   if(result.email)
                   {
                    setEmailError(AppMenus[0].email+' '+AppMenus[0].not_aval)
                   } 
                   if(result.mobile)    
                   {
                    setMobileError(AppMenus[0].mobile+' '+AppMenus[0].not_aval)
                   }
                   if(result===5)
                   {
                    setEnterpassword(true);
                   }
                }
            };
             mobil_email();    

        }
    };
        
    
    
    return (
        <div>
            <Container className="mt-4 mb-4">
          <h4>{AppMenus[0].profile}</h4>
          <Col
            md={6}
            sm={10}
            xs={10}
            className="mx-auto p-4 "
            style={{ border: "2px solid", backgroundColor: "#f2f6fc" }}
          >

{profileLoading? <>
<Spinner/>

</>:   enterPassword?<>
<Form onSubmit={final_submission} style={{ margin: '5px' }}>
                            <Form.Group controlId="formsubmit" style={{ textAlign: "left" }}>
                                <Form.Label>{AppMenus[0].password}</Form.Label>
                                <Form.Control
                                    type="password"
                                    name="password"
                                    placeholder={AppMenus[0].password}
                                    autoFocus={true}
                                    value={password}
                                    onChange={(e) => { SetPassword(e.target.value); SetPasswordError(''); } }
                                    isInvalid={password_error != ''} />
                                <Form.Control.Feedback
                                    type="invalid"
                                    style={{ textAlign: "left" }}
                                >
                                    {password_error}
                                </Form.Control.Feedback>
                            </Form.Group>
                            {isprocess ? <Spinner style={{ color: '#468094',marginTop: '10px'}} /> :
                                <Button  style={{marginTop:'10px', backgroundColor: '#468094', border: '1px solid #468094' }} type="submit">
                                    {AppMenus[0].submit}
                                </Button>}
                        </Form>    

</>:       
           <><div style={{ display: 'flex', justifyContent: 'center' }}> <MyCircle /></div><Form onSubmit={handleSubmit} style={{ margin: '5px' }}>
                            <Form.Group controlId="formName" style={{ textAlign: "left" }}>
                                <Form.Label>{AppMenus[0].name}</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    placeholder={AppMenus[0].namep}
                                    autoFocus={true}
                                    value={name}
                                    onChange={(e) => { setname(e.target.value); setNameError(''); } }
                                    isInvalid={nameError != ''} />
                                <Form.Control.Feedback
                                    type="invalid"
                                    style={{ textAlign: "left" }}
                                >
                                    {AppMenus[0].name_error}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group
                                controlId="formDistrict"
                                style={{ textAlign: "left" }}
                                className="mt-3"
                            >
                                <Form.Label>{AppMenus[0].district}</Form.Label>
                                <Form.Select
                                    name="district"
                                    value={district}
                                    onChange={(e) => { setdistrict(e.target.value); setDistrictError(''); } }
                                    isInvalid={districtError != ''}
                                >
                                    <option value="">{AppMenus[0].district}</option>
                                    {district_option}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    {AppMenus[0].district_error}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group
                                controlId="formMobile"
                                style={{ textAlign: "left" }}
                                className="mt-3"
                            >
                                <Form.Label>{AppMenus[0].mobile}</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="mobile"
                                    placeholder={AppMenus[0].mobilep}
                                    value={mobile}
                                    onChange={(e) => { setmobile(e.target.value); setMobileError(''); } }
                                    isInvalid={mobileError != ''} />

                                <Form.Control.Feedback type="invalid">
                                    {mobileError}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group
                                controlId="formEmail"
                                style={{ textAlign: "left" }}
                                className="mt-3"
                            >
                                <Form.Label>{AppMenus[0].email}</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="email"
                                    placeholder={AppMenus[0].email}
                                    value={email}
                                    onChange={(e) => { setemail(e.target.value); setEmailError(''); } }
                                    isInvalid={emailError !== ''} />
                                <Form.Control.Feedback type="invalid">
                                    {emailError}

                                </Form.Control.Feedback>
                            </Form.Group>



                            {isprocess ? <Spinner style={{ color: '#468094',marginTop: '10px'}} /> :
                                <Button  style={{marginTop:'10px', backgroundColor: '#468094', border: '1px solid #468094' }} type="submit">
                                    {AppMenus[0].submit}
                                </Button>}
                            
                        </Form></>
}
          </Col>
        </Container>
        </div>
    )
}
export default MyProfile
