import { Container,Row, Col,Form, Button, Spinner, Alert } from "react-bootstrap"
import { useRecoilValue } from "recoil"
import { appmenus } from "./Header/States/Atom_define"
import { useState } from "react";
import { forgotPass } from "./Query_Fetcher";
import Fg_OTP from "./Fg_OTP";

 function Forgot_Password()
{
    const AppMenus = useRecoilValue(appmenus);
    const [isloading,setloading] = useState(false);
    const [email_error,setemailError] = useState('');
    const [otp_error,setotpError] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [isshowbox,setShowBox] = useState(false);
    const handleSubmit = async (event) => {
        event.preventDefault();
        if(inputValue==='')
        {
        setemailError(true);
        }
        else
        {
          setloading(true);
            const data = new FormData()
            data.append('username',inputValue);
            const mydata = await forgotPass(data);
            setloading(false);
            console.log(mydata.data)
            if(mydata.data==='Max otp Generate')
            {
                setotpError(AppMenus[0].max_otp_try);
            }
            else if(mydata.data==='No user Found')
            {
                setotpError(AppMenus[0].nt_show);
            }
            else if (mydata.data===1)
            {
                setShowBox(true);
            }



            
        }
      };
    
    return(
        <div>
          {!isshowbox ?
            <Container>
                <Row>
                    <Col sm={{span:'8',offset:'2'}} style={{marginTop:'15px',boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',padding:'20px'}}>
                   <div style={{textAlign:'left',fontWeight:'bold'}}>{AppMenus[0].fgpass}</div>
                    <div >
                    <Form style={{marginTop:'15px'}} onSubmit={handleSubmit}>
            <Form.Group
              controlId="formBasicEmail"
              style={{ textAlign: "left" }}
            >
              <Form.Label>
                {AppMenus[0].email}/{AppMenus[0].mobile}
              </Form.Label>
              <Form.Control
                type="text"
                placeholder={AppMenus[0].user_name}
                autoFocus={true}
                isInvalid={email_error}
                onChange={(e) => {setemailError(false);setotpError('');setInputValue(e.target.value)}}
              />
            {email_error?<span style={{color:'red'}}>{AppMenus[0].user_name}</span>:null}
            </Form.Group>
          {isloading?<Spinner style={{marginTop:'10px', color:'#468194'}}/>: <Button type="submit" style={{marginTop:'10px',backgroundColor: '#468194', border: '1px solid rgb(70, 128, 148)'}}>{AppMenus[0].submit}</Button>}
            </Form>
            {otp_error!==''?<Alert style={{marginTop:'15px'}} variant="danger">{otp_error}</Alert>:null}
                    </div>
                    </Col>
                </Row>
            </Container>:
            <Fg_OTP user_data = {inputValue} />}
        </div>
    )
}

export default Forgot_Password