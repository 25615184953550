import { Card,Button, Alert, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import QuizResultThis from "./QuizResultThis";
import { useRecoilValue } from "recoil";
import { appmenus, countryName, islogin, languageName, stateCode, stateName } from "./Header/States/Atom_define";
import { useEffect } from "react";
import { useState } from "react";
import User_Pic from "./User_Pic";
function ResultComponent(props)
{
    const mylang = useRecoilValue(languageName);
    const st_name = useRecoilValue(stateName);
    const mylogin = useRecoilValue(islogin);
    const cn_name = useRecoilValue(countryName);
    const [state_value, setStatevalue] = useState('all');
    const st_code = useRecoilValue(stateCode);
    const [districtValue, setDistrictValue] = useState('all');

    const [nationalvalue, setnationvalue] = useState('current');
    const mydata = QuizResultThis(props.table_name,nationalvalue,'all',districtValue);
      const mymenus = useRecoilValue(appmenus);
      const [disdata, setdisdata] =  useState("");
        useEffect(() => {
    setdisdata(mydata);
    
  }, [mydata])
  useEffect(() => {
   if(!mylogin)
   {
    setDistrictValue('all');
   }
  }, [mylogin]);
  

function formatValue(value) {
    if (value < 999) {
      return value;
    } else {
      const dividedValue = value / 1000;
      if (Number.isInteger(dividedValue)) {
        return dividedValue+(mylang === 'en' ? 'K' : ' ह.');
      } else {
        return dividedValue.toFixed(1)+(mylang === 'en' ? 'K' : ' ह.');
      }
    } 
  }
  function hasValidStatus() {
    if(Array.isArray(disdata)){
    return disdata.some(
      item =>
        item.hasOwnProperty('status') &&
        (item.status === 'matched' || !isNaN(item.status))
    );
    }
    else
    {
      return false
    }
  }
console.log(disdata);
    return(
        <div>

<Card style={{boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'}}>
              <div style={{padding:'5px'}}>
              <div style={{marginTop:'10px',fontWeight:'bold'}}>  {props.table_name==='india'?cn_name:st_name}
                {' '+mymenus[0].tnqr}</div>
           <hr/>
           <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          
      
      <div>

        <Button style={{border:'1px solid black'}} variant={districtValue==='all'?'light':'info'}  onClick={()=> {
           if(mylogin) {
           if (districtValue==='all') {
                setDistrictValue('dist');
                setStatevalue(st_code);
            }
            else
            {
                setDistrictValue('all');
            }}
            else
            {
                             
                alert("जिला आधारित रैंक देखने के लिए कृपया लॉग इन करें");
              
            }
           
            }}>{mymenus[0].my_dist}</Button>
      </div>
    </div>
              </div>
              <Card.Body>
                
                {Array.isArray(disdata)?disdata.length===0?<Alert>{mymenus[0].nt_show}</Alert>:
                 <table style={{ width: '100%' }}>
  <thead>
    <tr>
      <th>Rank</th>
      
      <th>{mymenus[0].name}</th>
            <th>{mymenus[0].district}</th>
    </tr>
  </thead>
  <tbody>
    {Array.isArray(disdata) ? (
      disdata.map((item, index) => (
        <tr key={index} style={{backgroundColor: index % 2 === 0?'#faf7f0':'#dfe0f2', color:item.status? item.status === 'not matched' ? 'black' : 'red':'black' }}>
          <td style={{fontSize:'25px',fontWeight:'bold'}}>  {item.status ? item.status === 'matched' || item.status === 'not matched' ? index + 1 : item.status:index +1}</td>
           {/* <td> This is a comment   <User_Pic name={item.name} image = {item.image_url} /> </td> */} <td>{item.name}<br/> <small> <strong>{item.id_count-1}</strong>{' '+mymenus[0].w_other}</small></td>
          <td>{item[`${mylang}_dist`] } </td>
        </tr>

      ))
    ) : (
      
        <Spinner style={{color:'#1084A5'}}/>
      
    )}
  </tbody>
 
</table>:<Spinner style={{color:'#1084A5'}}/>}
{districtValue!=='all'?<div className="m-3" style={{color:'#7d807e'}}><small>{mymenus[0].its_district}</small></div>:''}
<Button style={{backgroundColor:'#1084A5',borderColor:'#1084A5'}} type="button" className="mt-3" onClick={() => {
          if (nationalvalue === 'current') {
            setnationvalue('previous');
          } else if (nationalvalue === 'previous') {
            setnationvalue('current');
          }
        }}>{nationalvalue==='current'?mymenus[0].previous:mymenus[0].right_now}</Button>

              </Card.Body>
            </Card>



        </div>

    )
}
export default ResultComponent;