import { Container, Row, Col } from "react-bootstrap"

function Terms_conditions()
{
    return(
        <Container>
               <Row>
               <Col  style={{textAlign:'justify', marginTop:'10px',boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',padding:'10px', }} sm={{span:'6',offset:'3'}}>
                <h5>Terms and Conditions</h5>

<h6>Disclaimer</h6>
<p>
  **Accuracy of Information**: While we strive to provide accurate and up-to-date information on the MP GKC app, it's important to understand that any content presented here is based on the best of our knowledge. However, occasional typographical errors or inaccuracies may occur. We want to emphasize that we cannot guarantee success in any job or competition solely based on your preparation through our app. The use of this app and its content is at your own risk, and we recommend cross-verifying critical information.
  </p>

<h6>Quiz Rankings</h6>
<p>
  **Performance in Quizzes**: The MP GKC app offers quizzes to help you gauge your knowledge and skills. Please note that achieving a specific rank in these quizzes does not confer upon you any tangible rewards, financial benefits, accommodation, job offers, or any other form of compensation or advantage from our side. Your quiz rankings are meant to showcase your relative performance within the app and do not translate into real-world rewards or opportunities.</p>

<h6>Acceptance of Terms</h6>
<p>
  **Agreement to Terms and Conditions**: By using the MP GKC mobile app, you are acknowledging and agreeing to abide by the terms and conditions outlined here. If you do not agree with any part of these terms, we kindly ask that you refrain from using the app.</p>

<h6>Changes to Terms and Conditions</h6>
<p>
  **Updates and Revisions**: We reserve the right to make changes or updates to these terms and conditions as needed. Any modifications will become effective immediately upon being posted on this page. We encourage you to periodically review this section to stay informed about any alterations. Your continued use of the app after such changes constitute your acceptance of the updated terms and conditions.</p>

<h6>Contact Us</h6>
<p>
  **Questions and Concerns**: If you have any questions, concerns, or require clarification regarding these terms and conditions, please do not hesitate to contact us at support@mpgkc.in. We are here to assist you and provide any necessary information.</p>

<p>
  Thank you for choosing and using the MP GKC app while adhering to these terms and conditions. We appreciate your trust and commitment to making the most of our content while understanding the limitations and responsibilities outlined herein.
</p>
               </Col>     

                </Row> 
        </Container>
        )
}
export default Terms_conditions