import { Container, Row, Col } from "react-bootstrap";
import "./Header.css";
import { useRecoilValue, useRecoilState } from "recoil";
import {
  appmenus,
  countryName,
  languageName,
  stateCode,
  stateName,
} from "./States/Atom_define";
import { useQuery } from "react-query";
import logo from "../../../src/logo.png";
import { useState } from "react";
import { useEffect } from "react";
import { fetchAppdata } from "../Query_Fetcher";

import ProfileComponent from "./ProfileComponent";
function Header() {
  const [AppMenus, setappmenus] = useRecoilState(appmenus);


  const language = useRecoilValue(languageName);
  const { issuccess, status, error, data } = useQuery({
    queryKey: ["AppMenus", language],
    staleTime: Infinity,
    queryFn: () => fetchAppdata("AppMenus", language),
  });
  useEffect(() => {
    if (status === "success") {
      setappmenus(data.data);
    }
  }, [status, language]);

  return (
    <div>
      <Container fluid style={{ backgroundColor: "#0D7091" }}>
        <Row>
          <Col>
            <div>
              <h4 style={{color:'white'}}>MP-GKC</h4><span style={{color:'white'}}>General Knowledge & Current Affair</span>
            </div>
            
        </Col>
        </Row>
        <Row>
          <Col className="text-end">
          
            {AppMenus[0].greeting || AppMenus[0].greeting != null ? (
              <ProfileComponent></ProfileComponent>
            ) : (
              "Loading"
            )}
          
          </Col>
          </Row>
      </Container>
    </div>
  );
}

export default Header;
