import { Container,Row, Col } from "react-bootstrap"

function Contact()
{
    return(
        <div>
            <Container>
                <Row>
                <Col  style={{textAlign:'justify', marginTop:'10px',boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',padding:'10px', }} sm={{span:'6',offset:'3'}}>
                    <h5>Contact Us</h5>

<h6>General Inquiries</h6>
<p>
  **Have Questions or Feedback?** We're here to help! If you have any general inquiries, suggestions, or feedback regarding the MP GKC app, please feel free to reach out to us at support@mpgkc.in. We value your input and strive to provide the best possible experience.
  </p>

<h6>Technical Support</h6>
<p>
  **Experiencing Technical Issues?** If you encounter technical difficulties while using the app, such as crashes, errors, or any other issues, please don't hesitate to contact our dedicated technical support team at support@mpgkc.in. They will assist you in resolving any technical challenges you may face.
  </p>

<h6>Privacy and Data Concerns</h6>
<p>
  **Privacy and Data Questions?** Your privacy is important to us. If you have concerns or questions regarding your personal data or privacy while using the MP GKC app, please reach out to our privacy team at [Insert Privacy Contact Email]. We take data protection seriously and are committed to addressing your inquiries.
  </p>

<h6>Business Partnerships</h6>
<p>
  **Interested in Collaboration?** If you're interested in exploring potential business partnerships, collaborations, or sponsorships with MP GKC, please get in touch with our partnerships team at <strong>vishal.sohanicse@gmail.com</strong>. We welcome opportunities to work together.

  </p>



<p>
  We value your communication and aim to respond to your inquiries promptly. Please ensure you provide us with accurate contact information so that we can assist you effectively.
  </p>

<p>
  Thank you for reaching out to MP GKC. We look forward to assisting you and enhancing your experience with our app.
</p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
export default Contact