import React, { useState, useEffect } from "react";
import { Button, Row,Col } from "react-bootstrap";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import LanguageList from "./LanguageList";
import { useQuery } from "react-query";
import { appmenus, countryName, islogin, languageName, myname, stateCode, stateName,user_img } from "./States/Atom_define";
import { Link, useNavigate } from "react-router-dom";
import { LogOut } from "../Query_Fetcher";
import { Offcanvas } from "react-bootstrap";
import { List } from "react-bootstrap-icons";
import MyCircle from "../Circle";
function ProfileComponent() {
  const AppMenus = useRecoilValue(appmenus);
  const [loginstatus, setlogin] = useRecoilState(islogin);
  const statename = useRecoilValue(stateName);
  const setmyimage = useSetRecoilState(user_img);
  const cname = useRecoilValue(countryName);
  const language = useRecoilValue(languageName);
  const state_code = useRecoilValue(stateCode);
  const isname = useRecoilValue(myname);
  const [islogout, setlogout] = useState(false);
  const setmyname = useSetRecoilState(myname);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  useEffect(() => {
    if (AppMenus[0] && AppMenus[0].login !== '') {
       }
  }, []);

  const { data, status, remove } = useQuery({
    queryKey: ["LogOut"],
    cacheTime: 0,
    queryFn: () => LogOut("LogOut"),
    enabled: islogout,
  });

  useEffect(() => {
    if (status === 'success' && data.data === 1) {
      setlogin(false);
      setlogout(false);
      setmyname('emp');
      setmyimage('');
      navigate(`/${language}/login`);
      remove();
    }
  }, [status, loginstatus, data, language, state_code, islogout, remove]);

  const handleLogout = () => {
    setlogout(true);
  };

 
 



  const renderDropdown = () => {
    return (
<>
      <Button  style={{ backgroundColor: 'transparent', border: "1px solid white"}} onClick={handleShow} >
      {loginstatus ? `${AppMenus[0].greeting}, ${isname}` : `${AppMenus[0].greeting}, ${AppMenus[0].guest}`} <List size={25}/>
    </Button>

<Offcanvas show={show} onHide={handleClose} placement="end" style={{width:'300px'}}>
<Offcanvas.Header closeButton>
  {/* <MyCircle/>  */}
  
  <Offcanvas.Title style={{fontSize:'15px'}}>{loginstatus ? `${AppMenus[0].greeting}, ${isname}` : `${AppMenus[0].greeting}, ${AppMenus[0].guest}`}</Offcanvas.Title>
</Offcanvas.Header>
<hr />
<Offcanvas.Body>
{loginstatus ? (
            <>
              <div style={{ background: "#1084A5",cursor:'pointer',color:'white', margin:'1px 0px 0px 12px',border:'1px solid black',textAlign:'center',borderRadius:'50px',padding:'10px'}} onClick={()=>{navigate(language + '/state-current-notes');setShow(false)}}> {`${AppMenus[0].state_short} ${AppMenus[0].ca} ${AppMenus[0].mnotes}`}</div>
              <div style={{backgroundColor:'#1084A5',cursor:'pointer',color:'white', margin:'10px 0px 0px 12px',border:'1px solid black',textAlign:'center',borderRadius:'50px',padding:'10px'}} onClick={()=>{navigate(language + '/country-current-notes');setShow(false)}}>  {`${cname} ${AppMenus[0].ca} ${AppMenus[0].mnotes}`}</div>
              <div style={{backgroundColor:'#1084A5',cursor:'pointer',color:'white', margin:'10px 0px 0px 12px',border:'1px solid black',textAlign:'center',borderRadius:'50px',padding:'10px'}} onClick={()=>{navigate(language + '/my-profile');setShow(false)}}>  {AppMenus[0].profile}</div>
              <div style={{backgroundColor:'#1084A5',cursor:'pointer',color:'white', margin:'10px 0px 0px 12px',border:'1px solid black',textAlign:'center',borderRadius:'50px',padding:'10px'}} onClick={()=>{handleLogout();setShow(false)}}>{AppMenus[0].lo}</div>
            </>
          ) : (
              <>
              <div style={{backgroundColor:'#1084A5',cursor:'pointer',color:'white', margin:'10px 0px 0px 12px',border:'1px solid black',textAlign:'center',borderRadius:'50px',padding:'10px'}} onClick={()=>{navigate(language + '/register');setShow(false)}}>  {AppMenus[0].register}</div>
                <div style={{backgroundColor:'#1084A5',cursor:'pointer',color:'white', margin:'10px 0px 0px 12px',border:'1px solid black',textAlign:'center',borderRadius:'50px',padding:'10px'}} onClick={()=>{navigate(language + '/login');setShow(false)}}>{AppMenus[0].login}</div>
              </>
            )}
          <hr />
          
          {/*  
          <div className="row ms-1">
            <div className="col">
              {AppMenus[0].mylan}
            </div>
            <div className="col">
              <LanguageList></LanguageList>
            </div>
          </div> */}
</Offcanvas.Body>
<div style={{backgroundColor:'#074D69', padding:'5px',color:'white',fontSize:'15px'}}>
  <div style={{display:'flex', justifyContent:'space-between'}}>
<div style={{borderRight:'2px solid white',paddingRight:'10px', cursor:'pointer'}} onClick={()=>{navigate(`/${language}/help`);setShow(false)}}>{AppMenus[0].help}</div>
<div style={{borderRight:'2px solid white',paddingRight:'10px',cursor:'pointer'}} onClick={()=>{navigate(`/${language}/contact`);setShow(false)}}>{AppMenus[0].contact}</div>
<div style={{borderRight:'2px solid white',paddingRight:'10px',cursor:'pointer'}} onClick={()=>{navigate(`/${language}/privacy-policy`);setShow(false)}}>{AppMenus[0].p_p}</div>
<div style={{cursor:'pointer'}} onClick={()=>{navigate(`/${language}/terms-conditions`);setShow(false)}}>{AppMenus[0].t_c}</div>
  </div>

<div style={{display:'flex',justifyContent:'center'}}>

<div>
{AppMenus[0].cp} &copy; MP GKC
</div>

</div>
  
</div>
</Offcanvas>

</>
     
    );
  };

  return (
    
      renderDropdown()
   
  );
}

export default ProfileComponent;
