import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useRecoilValue } from "recoil";
import {
  appmenus,
  countryName,
  languageName,
  stateName,
} from "./Header/States/Atom_define";
import { useNavigate } from "react-router";
import { useLocation } from "react-router";
import {
  Geo,
  GlobeCentralSouthAsia,
  House,
  HouseAddFill,
} from "react-bootstrap-icons";
function Footer_Menu() {
  const AppMenus = useRecoilValue(appmenus);
  const country = useRecoilValue(countryName);
  const [selectedid, setselectedid] = useState(0);
  const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);
  const [isScrollingUp, setIsScrollingUp] = useState(null);
  const [visible, setVisible] = useState(true);
  const language = useRecoilValue(languageName);
  const navigate = useNavigate();
  let location = useLocation();
  const path = location.pathname;
  const mydata = path.split("/");
  useEffect(() => {
    if (mydata[2] === "state") {
      setselectedid(1);
    } else if (mydata[2] === "india") {
      setselectedid(2);
    } else if (mydata[2] === "") {
      setselectedid(0);
    } else {
      setselectedid("");
    }
  }, [mydata]);

  return (
    <div style={{ marginTop: "60px" }}>
      <Container
        fluid
        style={{
          backgroundColor: "#074D69",
          position: "fixed",
          bottom: "0",
          borderTop: "1px solid black",
        }}
      >
        <Row>
          <Col
            onClick={() => {
              setselectedid(0);
              navigate("/" + language + "/");
            }}
            style={{
              borderRight: "1px solid white",
              height: "100%",
              cursor: "pointer",
              backgroundColor: selectedid === 0 ? "#6e680c" : "",
            }}
          >
            <House size={20} color="white" />
            <div style={{ padding: "0px 15px", color: "white" }}>Home</div>
          </Col>
          <Col
            onClick={() => {
              setselectedid(1);
              navigate("/" + language + "/state/");
            }}
            style={{
              borderRight: "1px solid white",
              height: "100%",
              cursor: "pointer",
              backgroundColor: selectedid === 1 ? "#6e680c" : "",
            }}
          >
            {" "}
            <Geo size={20} color="white" />
            <div style={{ padding: "0px 15px", color: "white" }}>
              {AppMenus[0].state_short}
            </div>
          </Col>
          <Col
            onClick={() => {
              setselectedid(2);
              navigate("/" + language + "/india/");
            }}
            style={{
              cursor: "pointer",
              backgroundColor: selectedid === 2 ? "#6e680c" : "",
            }}
          >
            <GlobeCentralSouthAsia size={20} color="white" />
            <div
              style={{ padding: "0px 15px", color: "white", cursor: "pointer" }}
            >
              {country}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Footer_Menu;
