import secureLocalStorage from "react-secure-storage";
import { atom } from "recoil";
let st = "emp";
let lg = "hi";
let cn = "emp";


if (secureLocalStorage.getItem("statename")) {
  st = secureLocalStorage.getItem("statename");
}
const stateName = atom({
  key: "StateName", // unique ID (with respect to other atoms/selectors)
  default: st, // default value (aka initial value)
});


const stateCode = atom({
  key: "StateCode", // unique ID (with respect to other atoms/selectors)
  default: 'mp', // default value (aka initial value)
});

if (secureLocalStorage.getItem("lang")) {
  lg = secureLocalStorage.getItem("lang");
}
const languageName = atom({
  key: "LangName", // unique ID (with respect to other atoms/selectors)
  default: lg, // default value (aka initial value)
});

if (secureLocalStorage.getItem("country")) {
  cn = secureLocalStorage.getItem("country");
}
const countryName = atom({
  key: "CountryName", // unique ID (with respect to other atoms/selectors)
  default: cn, // default value (aka initial value)
});
const statemenus = atom({
  key: "statemenus", // unique ID (with respect to other atoms/selectors)
  default: 'blank', // default value (aka initial value)
});
const countrymenus = atom({
  key: "countrymenus", // unique ID (with respect to other atoms/selectors)
  default: 'blank', // default value (aka initial value)
});

const appmenus = atom({
  key: "appmenus", // unique ID (with respect to other atoms/selectors)
  default: 'blank', // default value (aka initial value)
});

const urlvariables = atom({
  key:'urlvariables',
  default:true
});
const islogin = atom({
  key:'islogin',
  default: false,
})
const myname = atom({
  key:'myname',
  default:'emp',
})
const paid = atom({
  key:'paid',
  default:false,
})
const querykeyval = atom({
  key:'querykeyval',
  default:0,
})
const user_img = atom({
  key:'user_img',
  default:'',
})

const connection = atom({
  key:'connection',
  default:navigator.onLine,
})

export {connection,urlvariables,querykeyval,stateName, paid, languageName, myname, countryName, stateCode,statemenus,countrymenus,appmenus,islogin,user_img };
