import { useEffect, useState } from "react";
import Month from "./Month";
import {
  Card,
  Button,
  Container,
  Row,
  Col,
  Alert,
  Form,
} from "react-bootstrap";
import { useRecoilValue } from "recoil";
import {
  appmenus,
  countrymenus,
  languageName,
  statemenus,
} from "./Header/States/Atom_define";
import { useQuery } from "react-query";
import { fetchCurrentNotes} from "./Query_Fetcher";
import { useMutation, useQueryClient } from "react-query";
import { current_up_del } from "./Query_Fetcher";
import DOMPurify from "dompurify";
import { useLocation } from "react-router";
function CurrentNotes() {
  let location = useLocation();
  const cn_menu = useRecoilValue(countrymenus);
  const path = location.pathname;
  const [page, setPage] = useState(1);
  const myurl = path.split("/");
  let st_code;
  if(myurl[2]==='country-current-notes')
  {
    st_code='india';
  }
  else
  {
    st_code='state';
  }

  
  const applang = useRecoilValue(appmenus);
  const lang = useRecoilValue(languageName);
  
  const state_menus = useRecoilValue(statemenus);
  const [selectedOption, setSelectedOption] = useState("all");
  const queryClient = useQueryClient();
  const propertyName = `des_${lang}`;
  const mydata = new FormData();
  mydata.append("My_state_notes", st_code);
  mydata.append("option", selectedOption);
  mydata.append("page", page);

  const {
    isSuccess,
    status,
    error,
    data: result,
    
  } = useQuery(["My_state_notes", st_code, selectedOption, page], () =>
    fetchCurrentNotes("My_state_notes", mydata),
  );

  const handleLoadMore = (val) => {
    if (val == "increment") {
      setPage((prevPage) => prevPage + 1);
      mydata.set("page", page + 1);
    } else {
      setPage((prevPage) => prevPage - 1);
      mydata.set("page", page - 1);
    }
  };

  const handleOptionChange = (value) => {
    const selectedValue = value;
   mydata.set("option", selectedValue);
    setSelectedOption(selectedValue);
    setPage(1);
    // Update the state variable or perform any other action based on the selected option
  };
  const buttonMutation = useMutation(current_up_del);
  const handleButtonClick = (
    contentUrl,
    stCode,
    id,
    notes_id,
    operationValue
  ) => {
    buttonMutation
      .mutateAsync([
        "update_Notes",
        contentUrl,
        stCode,
        id,
        notes_id,
        operationValue,
      ])
      .then((data) => {
        let updatedData = result.data;
        if (operationValue === "delete") {
          updatedData = updatedData.filter((item) => item.id !== id);

          if (updatedData.length === 0) {
            if (page != 1) {
              setPage((prevPage) => prevPage - 1);
            }
            if (page === 1) {
              setPage((prevPage) => prevPage + 1);
            }
          }

          queryClient.setQueryData(
            ["My_state_notes", st_code, selectedOption, page],
            { data: updatedData }
          );
        }
    


      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div>
      <Container>
        <Row>
          <Col sm={8}>
            <Row
              className="align-items-center"
              style={{
                marginLeft: "7px",
                marginTop: "10px",
                padding: "10px",
                boxShadow:
                  "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
              }}
            >
              <Col sm={4}>
                <Form.Label>{applang[0].srch_cat}</Form.Label>
              </Col>
              <Col sm={8}>
              <Month onOptionChange={handleOptionChange} />
  
              </Col>
            </Row>
            <Row style={{ marginTop: "20px" }}>
              {result?.data && result?.data.length === 0 ? (
                <div style={{ marginTop: "10px" }}>
                  <Alert variant="danger"> {applang[0].no_notes}</Alert>{" "}
                </div>
              ) : (
                ""
              )}
              {result?.data.map((item, index) => (
                <div key={item.id}>
                  <Card
                    style={{
                      boxShadow:
                        "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                      marginBottom: "20px",
                      backgroundColor: index % 2 === 0 ? "#f2f2f2" : "",
                    }}
                  >
                    <Card.Body>
                      <div className="d-flex justify-content-between align-items-center">
                       <div>
                          {item.hasOwnProperty(`title_${lang}`)
                            ? item[`title_${lang}`]
                            : ""}
                      </div>  
                        <div>
                          <Button
                            key={item.id}
                            variant="outline-primary"
                            style={{
                              backgroundColor: "blue",
                              color: "white",
                            }}
                            onClick={() =>
                              handleButtonClick(
                                "content_url",
                                st_code,
                                item.id,
                                item.notes_id,
                                "delete",
                                item.counter
                              )
                            }
                          >
                            {item.notes_id
                              ? applang[0].already_notes
                              : applang[0].add_notes}
                          </Button>
                        </div>
                      </div>
                      <hr />
                      <Card.Text
                        style={{ textAlign: "left" }}
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(item[propertyName]),
                        }}
                      ></Card.Text>
                      <hr />
                      <div
                        style={{
                          position: "absolute",
                          bottom: "10px",
                          right: "12px",
                          color: "gray",
                          fontSize: "12px",
                        }}
                      >
                        {item.counter} {applang[0].n_st}
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              ))}

            </Row>

            <Container style={{marginTop:'10px'}}><Row>
      <Col  className="text-start">
        {page === 1 ? (
          ""
        ) : (
          <Button onClick={() => handleLoadMore("decrement")}>Previous</Button>
        )}
      </Col>
      <Col  className="text-end">
        {result?.data.length === 0 ? (
          ""
        ) : (
          <Button onClick={() => handleLoadMore("increment")}>Next</Button>
        )}
      </Col>
     
    </Row></Container>



          </Col>

          
        </Row>
        
      </Container>
      


    </div>
  );
}

export default CurrentNotes;
