import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { appmenus, islogin, myname, user_img } from '../components/Header/States/Atom_define';
import { Modal, Button } from 'react-bootstrap';
import Image_Upload from './Image_Upload';


const MyCircle = () => {
    const thisname = useRecoilValue(myname);
    const [modalShow, setModalShow] = useState(false);
    const myimage = useRecoilValue(user_img);
    const mymenus = useRecoilValue(appmenus);
    const mylogin = useRecoilValue(islogin);
    console.log(mymenus)
    function handlemodal()
    {
        setModalShow(true);
    }
    const handleClose = () => setModalShow(false);
  return (
    <div>
    <Modal
      show = {modalShow}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleClose}
      
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        {mymenus[0].u_p_p}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body  style={{
                boxShadow:
                  "rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px",
              }}>
               
                    <Image_Upload closeModal = {handleClose}/>
       
      </Modal.Body>
    </Modal>
    <div className="circle">
     {console.log(thisname)} 
      {myimage!==''?<> <div className="circle-content"> <img src={`https://clsheilkmp.gkcmp.in/GKC/upload/${myimage}`} style={{borderRadius:'50%'}} height={'70px'} width={'70px'}/>
       <span onClick={()=>handlemodal()} className="circle-overlay">{mymenus[0].change_p}</span> </div> </>
      :
   <div className="circle-content">   <span className="circle-character">     {thisname? thisname.charAt(0).toUpperCase():'G' }</span>
   {mylogin? <span onClick={()=>handlemodal()} className="circle-overlay">{mymenus[0].upload}</span>:''}
   </div>
    }
       
      
       
       
    </div>
    </div>
  );
};

export default MyCircle;
