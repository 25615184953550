import { useState } from "react"
import { Container, Row, Col } from "react-bootstrap"

function Help ()
{
    const [isshow,setshow] = useState(false);
    return (
        <div>
            <Container>
                <Row>
                    <Col  style={{textAlign:'justify', marginTop:'10px',boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',padding:'10px', }} sm={{span:'6',offset:'3'}}>
                    <div>
      {!isshow?<><h5>Welcome to the MP GKC Help</h5><p>
                                Thank you for choosing MP GKC - your trusted source for the latest updates on current affairs and general knowledge related to Madhya Pradesh and India. We're here to assist you in making the most of our app. If you have any questions, queries, or suggestions, this Help Page is designed to provide you with the information you need.
                            </p><h6>Frequently Asked Questions</h6><h6>How do I navigate through the app?</h6><p>
                                    To explore the app, simply tap on the various categories and sections available in the main menu. You can find news, quizzes, and articles related to both Madhya Pradesh and India.
                                </p><h6>How can I stay updated with the latest content?</h6><p>
                                    We regularly update our app with fresh content. To ensure you're always in the know, enable notifications in your device settings to receive alerts whenever new information is available.
                                </p><h6>What if I encounter technical issues?</h6><p>
                                    If you experience any technical difficulties, such as app crashes or errors, please try closing and reopening the app. If the issue persists, you can reach out to our support team at <a href="mailto:support@mpgkc.in">support@mpgkc.in</a> for assistance.
                                </p><h6>How can I share interesting articles or quizzes with friends?</h6><p>
                                    You can easily share articles and quizzes with your friends and family by tapping the share icon within the app. It allows you to send content through various messaging and social media platforms.
                                </p><h4>Contact Us</h4><p>
                                    We value your feedback and suggestions. If you have any questions, concerns, or ideas to improve the app, please don't hesitate to contact us at <a href="mailto:support@mpgkc.in">support@mpgkc.in</a>. Our dedicated support team is here to assist you and enhance your experience with MP GKC.
                                </p><h2>Stay Informed, Stay Ahead</h2><p>
                                    At MP GKC, our goal is to provide you with the latest and most relevant information about Madhya Pradesh and India's current affairs and general knowledge. We hope you find our app informative and enjoyable. Thank you for choosing us as your go-to source for knowledge, and we look forward to serving you better each day.
                                </p><h5 onClick={()=>setshow(true)} style={{color:'blue'}}> Delete Your Account</h5></>
:
                                <>
                                <h5>How to Delete Account</h5>
                                <p>We're sorry to see you go, but if you've made the decision to delete your MP GKC account, we want to assist you in the process. We value your feedback and would appreciate hearing your reasons for leaving so that we can improve our service in the future.</p><p>If you're sure about deleting your account, please follow these steps:

                                    <li>Send an email to support@mpgkc.in from the email address associated with your MP GKC account.</li>

                                    <li>In the email subject, please mention "Account Deletion Request."</li>

                                    <li>In the body of the email, briefly state your reason for deleting your account (optional but helpful for us to improve).</li>

                                    <li>Our support team will process your request as soon as possible and send you a confirmation email once your account has been deleted.</li> </p><p>Remember that deleting your account means you'll lose access to all your saved preferences, bookmarks, and personalized content. If you change your mind in the future, you'll need to create a new account.</p><p>We appreciate the time you've spent with MP GKC and hope you consider returning to stay informed and engaged with the latest news and knowledge about Madhya Pradesh and India. If you have any further questions or need assistance, please feel free to reach out to us.</p>
                                    <div><h6 onClick={()=>setshow(false)} style={{color:'blue'}}>Back</h6></div>
                                    
                                    
                                    </>
}
    </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
export default Help