import { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useLocation } from "react-router";
import { useState } from "react";
import { Alert } from "react-bootstrap";
import ResultComponent from "./ResultComponent";
import KeyPoints from "./KeyPoints";
function Home() {
  const location = useLocation();
  const [message, setMessage] = useState("");
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const msg = searchParams.get("msg");
    if (msg) {
      setMessage(msg);
      hideMessage();
    }
  }, [location.search]);

  const hideMessage = () => {
    setTimeout(() => {
      setMessage("");
    }, 5000);
  };

 

  return (
    <div>
      <Container style={{marginBottom:'20px'}}>
      {message && <Alert variant="success">{message}</Alert>}
        <Row>
        <Col sm={8} style={{marginTop:'15px'}}>
            <KeyPoints type='india' />
            <div style={{marginTop:'15px'}}><KeyPoints type='state'/></div>
          </Col>
          <Col sm={4}>
          <div style={{marginTop:'15px'}}>            
<ResultComponent  table_name = {'india'}/>
</div>
<div style={{marginTop:'15px'}}>
<ResultComponent table_name = {'state'}/>
</div>
            
          </Col>

        </Row>
      </Container>
    </div>
  );
}

export default Home;
