import logo from "./logo.svg";
import "./App.css";
import "./components/Header/Header";
import "./index.css";
import Header from "./components/Header/Header";
import { useEffect } from "react";
import { ThemeProvider } from "react-bootstrap";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router";
import Home from "./components/Home";
import React from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {
  stateName,
  stateCode,
  languageName,
  countryName,
  urlvariables,
  islogin,
  myname,
  appmenus,
  user_img,
} from "./components/Header/States/Atom_define";
import {
  
 Checkfunction,
} from "./components/Query_Fetcher";
import { useQuery } from "react-query";
import StateCurrentAffair from "./components/StateCurrentAffair";

import StateDailyQuiz from "./components/StateDailyQuiz";

import MyProfile from "./components/MyProfile";
import Register from "./components/Register";
import Login from "./components/Login";
import NotFound from "./components/NotFound";
import CurrentNotes from "./components/CurrentNotes";
import MyModal from "./components/Header/MyModal";
import Footer_Menu from "./components/Footer_Menu";
import IndiaContent from "./components/IndiaContent";
import StateOptions from "./components/StateOptions";
import Forgot_Password from "./components/Forgot_Password";
import Help from "./components/Help";
import Contact from "./components/Contact";
import Privacy from "./components/Privacy";
import Terms_conditions from "./components/Terms_conditions";
function App() {
  let mylocation = useLocation();

  const myappmenus=useRecoilValue(appmenus);
  const [state, setstate] = useRecoilState(stateName);
  const setmyimage = useSetRecoilState(user_img);
  const [state_code, setstatecode] = useRecoilState(stateCode);
    const [language, setlanguage] = useRecoilState(languageName);
  const [country, setcountry] = useRecoilState(countryName);
  const[isurlvariable,seturlvariable] = useRecoilState(urlvariables);
  const [ismyname,setmyname] = useRecoilState(myname);

  const navigate = useNavigate();
  const answer_array = mylocation.pathname.split("/");
const dynamicPart = answer_array.slice(2).join("/");
const [isitlogin,setlogin] = useRecoilState(islogin);
  let stt_code;
  let lang;

  
   const { status:check,  data:checkdata } = useQuery({
    queryKey: ["check",language],
    staleTime:Infinity,
    queryFn: () => Checkfunction("check", language),
   });
useEffect(() => {
  if(check==='success')
{
    if(checkdata?.data===2)
  {
    setmyname(myappmenus[0].guest);
    setlogin(false);
    navigate('/'+language+'/'+state_code+'/login/', { state: { msg: { data: 'session expired please login again' } } });
  }
 else if(checkdata?.data===0)
 {
  setmyname(myappmenus[0].guest);
  setlogin(false);
  
 }
 else
 {
  setmyname(checkdata?.data.name);
  setmyimage(checkdata?.data.image_url);
  setlogin(true);
 }
}

  
}, [check])
console.log(answer_array)
useEffect(() => {
  if(isurlvariable){
  if (answer_array[1]) {
    setlanguage(answer_array[1]);
    seturlvariable(false);
  } else {

  }
}
}, [])

   
useEffect(() => {
  if(language==='hi')
  {
    setstate('मध्य प्रदेश');
    setcountry('भारत');
  }
  else
  {
    setstate('Madhya Pradesh');
    setcountry('India');
  }

}, [language]);




useEffect(() => {
  if(language)
  {
    let newUrl = `/${language}/${dynamicPart}`;
     navigate(newUrl);
  }
}, [language])

 



  return (
    <div className="App">
      <ThemeProvider
        breakpoints={["xxxl", "xxl", "xl", "lg", "md", "sm", "xs", "xxs"]}
        minBreakpoint="xxs"
      >
       
        {language === 'emp'? <MyModal/>: <Header />}

        
   
        <Routes>
          <Route path={language + "/"} element={<Home></Home>} />
          <Route path={language + "/help"} element={<Help/>} />
          <Route path={language + "/contact"} element={<Contact/>} />
          <Route path={language + "/privacy-policy"} element={<Privacy/>} />
          <Route path={language + "/terms-conditions"} element={<Terms_conditions/>} />
          
          

          {isitlogin?(
            <>

            <Route path={language +'/state-current-notes'} element={<CurrentNotes></CurrentNotes>} />
            <Route path={language +'/country-current-notes'} element={<CurrentNotes></CurrentNotes>} />
            <Route path={language +'/my-profile'} element={<MyProfile></MyProfile>} />
            </>
          ):(<><Route path={language +'/register'} element={<Register></Register>} />
          <Route path={language +'/login'} element={<Login></Login>} />
          <Route path={language +'/fg_pass'} element={<Forgot_Password/>} /> 
          
          </>)


          }    
          <Route path={language +'/state/state-quiz-today/morning-session'} element={<StateDailyQuiz></StateDailyQuiz>} />
          <Route path={language +'/state/state-quiz-today/evening-session'} element={<StateDailyQuiz></StateDailyQuiz>} />
          <Route path={language +'/india/india-quiz-today/morning-session'} element={<StateDailyQuiz></StateDailyQuiz>} />    
          <Route path={language +'/india/india-quiz-today/evening-session'} element={<StateDailyQuiz></StateDailyQuiz>} />    
          <Route path={language +'/state/state-current-affair'} element={<StateCurrentAffair></StateCurrentAffair>} />
          <Route path={language +'/india/india-current-affair'} element={<StateCurrentAffair></StateCurrentAffair>} />
          <Route path={language +'/india'} element={<IndiaContent/>} />
          <Route path={language +'/state'} element={<StateOptions/>} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer_Menu/>
      </ThemeProvider>
    </div>
  );
}


export default App;
