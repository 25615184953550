import { Container, Col, Row, Button } from "react-bootstrap";
import { useRecoilValue } from "recoil";
import { appmenus, countryName, languageName, stateName } from "./Header/States/Atom_define";
import { useNavigate } from "react-router";

function StateOptions() {
   const mylanguage = useRecoilValue(languageName);
   const mystate = useRecoilValue(stateName);
   const mycountry = useRecoilValue(countryName);
   const mymenus = useRecoilValue(appmenus);
   const navigate = useNavigate();
  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "70vh" }}>
      <Container >
        <Row >
          <Col sm={{ span: 6, offset: 3 }}>
            <div style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', height: '100%',padding:'30px 60px 60px 60px' }}>
                 <h5>{mystate}</h5>

              <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", height: '100%' }}>
                <Button onClick={()=>navigate('/'+mylanguage+'/state/state-quiz-today/morning-session')} variant="ouline" style={{ margin: '10px 0', backgroundColor:'#1e6785',color:'white',width:'100%' }}>{mymenus[0].today_quiz_m}</Button>
                <Button onClick={()=>navigate('/'+mylanguage+'/state/state-quiz-today/evening-session')} variant="ouline" style={{ margin: '10px 0',backgroundColor:'#1e6785',color:'white',width:'100%' }} >{mymenus[0].today_quiz_e}</Button>
                <Button onClick={()=>navigate('/'+mylanguage+'/state/state-current-affair')} variant="ouline" style={{ margin: '10px 0',backgroundColor:'#1e6785',color:'white',width:'100%' }}>{mymenus[0].ca}</Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default StateOptions;
