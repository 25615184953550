import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useRecoilValue } from 'recoil';
import { appmenus, islogin, languageName, stateCode } from './Header/States/Atom_define';
import { Current_Content,modifycurrent } from './Query_Fetcher';
import Month from './Month';
import {Alert, Form,Card, Button, Container,Row,Col } from 'react-bootstrap';
import DOMPurify from 'dompurify';
import { useLocation } from 'react-router';
import { useMutation } from 'react-query';
import { useEffect } from 'react';
function StateCurrentAffair() {
  const [page, setPage] = useState(1);
    let location = useLocation();
    const path = location.pathname;
    const mydata = path.split("/");
    let st_code ='';
    st_code = useRecoilValue(stateCode);
    useEffect(() => {
   
       setPage(1)
      
    }, [path])
    
   if(mydata[3]==='india-current-affair'){
      st_code='india';
      
     }  
     else
     {
      st_code='state';
  
     }

  const logcheck = useRecoilValue(islogin);
  const lang = useRecoilValue(languageName);
  const applang = useRecoilValue(appmenus);
  const queryClient = useQueryClient();
  const [sortBy, setSortBy] = useState("most_recent");
  const formData = new FormData();
  const [selectedOption, setSelectedOption] = useState('all');

  const propertyName = `des_${lang}`;
  formData.append('Current_Data', st_code);
 formData.append('option',selectedOption);
 formData.append('page',page);
 formData.append('sortby',sortBy);
const {
    isSuccess,
    status,
    error,
    data,
  } = useQuery({
    queryKey: ['Current_Content', st_code,selectedOption,page,sortBy],staleTime: 24 * 60 * 1000,
    queryFn: () => Current_Content('Current_Content', formData),
  });

  const handleOptionChange = (value) => {
    setSelectedOption(value);
    setPage(1);
    formData.set('option',value);
  };

const updatethis = new FormData();
updatethis.append('state','');
updatethis.append('content_id','');
updatethis.append('notes_id','');
updatethis.append('operation','');


const buttonMutation = useMutation(modifycurrent);
  const handleButtonClick = (
   stCode,
    id,
    notes_id,
    operationValue,counter
  ) => {
    updatethis.set('state',stCode);
    updatethis.set('content_id',id);
    updatethis.set('notes_id',notes_id);
    updatethis.set('operation',operationValue);
if(logcheck)
{
    
    //form data object
    buttonMutation
      .mutateAsync([
        "update_current",
        updatethis,
      ])
      .then((mydata) => {
       
        const updatedData = data.data.map((item) => {
            if (item.id === id) {
              
              return {
                ...item,
                notes_id: operationValue === "update" ? mydata : null,
                notes_content_id: operationValue === "update" ? id : null,
                counter:operationValue==="update"? parseInt(item.counter) + 1:parseInt(item.counter) - 1,
              };
            }
            return item;
          });



          queryClient.setQueryData(
            ["Current_Content", st_code, selectedOption, page, sortBy],
            { data: updatedData }
          );
        
          })
      .catch((error) => {
        console.error(error);
      });
    }
    else
    {
        alert('Please login to add in your notes');
    }
  };

  const handleLoadMore = (val) => {
    if (val == "increment") {
      setPage((prevPage) => prevPage + 1);
      formData.set("page", page + 1);
    } else {
      setPage((prevPage) => prevPage - 1);
      formData.set("page", page - 1);
    }
  };





  function MyData() {
    let previousMonth = "";
    let previousYear = "";
  
    return (
      <>
        {data?.data.map((item, index) => {
          const itemDate = new Date(item.insert_on);
          const locale = lang === 'hi' ? 'hi-IN' : 'en-US';
          const itemMonth = itemDate.toLocaleString(locale, { month: 'long' });
          const itemYear = itemDate.getFullYear();
  
          const isMonthChanged = (index === 0 || itemMonth !== previousMonth || itemYear !== previousYear);
  
          previousMonth = itemMonth;
          previousYear = itemYear;
  
          return (
            <div key={item.id}>
              {isMonthChanged && <div style={{textAlign:'right',}}>{itemMonth} {itemYear}</div>}
              <Card style={{ boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px', marginBottom: "20px", backgroundColor: index % 2 === 0 ? "#f2f2f2" : ""}}>
                <Card.Body>
                  <div className="d-flex justify-content-between align-items-center">
                    <div style={{fontWeight:'bold'}}>
                      {item.hasOwnProperty(`title_${lang}`)
                        ? item[`title_${lang}`]
                        : ""}
                    </div> 
                    <div>
                      <Button
                        key={item.id}
                        variant="outline-primary"
                        style={{
                          backgroundColor: item.notes_id ? "blue" : "",
                          color: item.notes_id ? "white" : "",
                          fontSize:'14px'
                        }}
                        onClick={() =>
                          handleButtonClick(
                            st_code,
                            item.id,
                            item.notes_id,
                            item.notes_content_id ? "delete" : "update",
                            item.counter
                          )
                        }
                      >
                        {item.notes_id ? applang[0].already_notes : applang[0].add_notes}
                      </Button>
                    </div>
                  </div>
                  <hr />
                  <Card.Text
                    style={{ textAlign: "left" }}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(item[propertyName]),
                    }}
                  ></Card.Text>
                  <hr/>
                  <div
                    style={{
                      position: "absolute",
                      bottom: "10px",
                      right: "12px",
                      color: "gray",
                      fontSize: "12px",
                    }}
                  >
                    {item.counter} {applang[0].n_st}
                  </div>
                </Card.Body>
              </Card>
            </div>
          );
        })}
      </>
    );
  }
  const handleSortByChange = (e) => {

    setSortBy(e.target.value);
    formData.set('sortby',e.target.value);
    setPage(1);
    // You can also update your query or perform any other action based on selected sort by option here
  };


  return (
    <div>
        <Container className='mt-4'>
            <Row>
            <Col sm={4}>
            <Form.Label>{applang[0].srch_cat}</Form.Label>
            <Month onOptionChange={handleOptionChange} />
            </Col>
            <Col sm={4}>
            <Form.Label>{applang[0].sorting} </Form.Label>
              <Form.Select
                id="sort-by-select"
                value={sortBy}
                onChange={handleSortByChange}
              >
                <option value="most_recent">{applang[0].rt_data}</option>
                <option value="most_added">{applang[0].m_notes}</option>
              </Form.Select>
            </Col>
            </Row>
          <Row>
            <Col sm={8}>
                
            </Col>
            </Row>  {data?.data && data?.data.length === 0 ? (
                <div style={{ marginTop: "10px" }}>
                  <Alert variant="danger"> {applang[0].no_notes}</Alert>{" "}
                </div>
              ) : (
                ""
              )}
            <Row>
                <Col sm={8}>
                    <MyData></MyData>
                    <Container style={{marginTop:'10px'}}><Row >
      <Col className="text-start">
        {page === 1 ? (
          ""
        ) : (
          <Button onClick={() => handleLoadMore("decrement")}>Previous</Button>
        )}
      </Col>
      <Col  className="text-end">
        {data?.data.length === 0 ? (
          ""
        ) : (
          <Button onClick={() => handleLoadMore("increment")}>Next</Button>
        )}
      </Col>
     
    </Row></Container>




                </Col>
            </Row>
        </Container>
 

    </div>
  );
}

export default StateCurrentAffair;
