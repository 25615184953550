import { useQuery } from "react-query";
import { fetch_key_notes } from "./Query_Fetcher";
import { useRecoilValue } from "recoil";
import { appmenus, countryName, languageName, stateName } from "./Header/States/Atom_define";
import { Spinner,Card, Button } from "react-bootstrap";
import { useNavigate } from "react-router";
function KeyPoints(props)
{
    const navigate = useNavigate()
    const mymenus = useRecoilValue(appmenus)
    const mystate = useRecoilValue(stateName)
    const mycountry = useRecoilValue(countryName)
    const lang = useRecoilValue(languageName)
    const {isLoading, isSuccess, status, error, data } = useQuery(
        ["key_notes", props.type,lang],
        () => fetch_key_notes("key_notes", props.type,lang),
              );
    return (
        <div>
            
            <Card style={{boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'}}>
            <div style={{fontWeight:'bold',textAlign:'left',margin:'10px'}}>{props.type==='state'? mystate :mycountry   }{'  '+mymenus[0].latest}</div>
                {isLoading?<div style={{display:'flex',justifyContent:'center'}}><Spinner  style={{color:'#0d7091'}}/></div>:
                <div>

                {data?.map((item, index) => (
                        <div key={index} style={{border:'1px solid black', margin:'10px', borderRadius:'50px',textAlign:'left',padding:'10px'}}>{item.data}</div>
                    ))}
                    <Button onClick={()=>navigate(props.type==='state'?'state/state-current-affair':'india/india-current-affair')} style={{backgroundColor:'#1085a5',margin:'10px',borderColor:'#1085a5'}}>{mymenus[0].more}</Button>
                </div>
                }
            </Card>
        </div>
    )
}
export default KeyPoints