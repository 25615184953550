import {Dropdown, DropdownButton } from "react-bootstrap"
import { useQuery } from "react-query";
import { fetchLanguageList } from "../Query_Fetcher";
import { useRecoilState, useRecoilValue } from "recoil"
import { languageName,stateCode } from "./States/Atom_define"
import { Navigate, useNavigate } from "react-router";
import secureLocalStorage from "react-secure-storage";
function LanguageList() {
 
    const state_code = useRecoilValue(stateCode)
const [language,setlanguage] = useRecoilState(languageName);
function handleSelectChange(event) {
  secureLocalStorage.setItem("lang",event.target.value);
  setlanguage(event.target.value);
  

}



const { issuccess,isloading, status, error, data } = useQuery({
    queryKey: "languagelist",
    staleTime: 30 * (60 * 1000),
    queryFn: () => fetchLanguageList("languagelist", 'language_list'),
  });
  if(isloading)
  {
    return <div>Loading</div>
  }

 let options;
 
 if(status==='success') {  
 
  options = data.data.map((language) => (
    <option key={language.lan_id} value={language.lan_id} >
      {language.lan_name}
    </option>
  ));
 
    }

  
    return (
        <div className="LanguageList">
          <select value={language} onChange={handleSelectChange} style={{border:'none',color:'blue'}}>
          {status==='success'?options:''}
          </select>
 
              
            
        </div>
    )
};
export default LanguageList