import { useQuery } from "react-query";
import { quizResulttable } from "./Query_Fetcher";
import { useEffect } from "react";
import { querykeyval } from "./Header/States/Atom_define";
import { useRecoilValue } from "recoil";
const QuizResultThis  =  (table,time,mystate, mydistrict) => {
  const mykeyval = useRecoilValue(querykeyval);
    const mydata = new FormData();
    mydata.append('table_name',table);
    mydata.append('time',time);
    mydata.append('state',mystate);
    mydata.append('district',mydistrict);

    useEffect(() => {
      
    refetch();
      
    }, [mykeyval])
    

    const { isSuccess, status, error, data, refetch } = useQuery(
      ["QuizResult", table, time, mystate,mydistrict],
      () => quizResulttable("Daily-Quiz", mydata),
      {
        staleTime: 60000, // Set the cache to expire after 2 minutes
        cacheTime:60000,
        
      }
    );
      let hello;
      if(status==='success' && data?.data){
        hello = data.data;
      }
      else
      {
            hello = 'nothing';
      }


return hello;
}
export default QuizResultThis