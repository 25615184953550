import { Form, Button, Spinner } from "react-bootstrap";
import Registration from "./Register";
import { Link } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  appmenus,
  islogin,
  languageName,
  stateCode,
  myname,
  user_img,
} from "./Header/States/Atom_define";
import { useLocation, useNavigate } from "react-router";
import { useState } from "react";
import { Alert } from "react-bootstrap";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { Login_Form } from "./Query_Fetcher";
function Login() {
  const AppMenus = useRecoilValue(appmenus);
  const language = useRecoilValue(languageName);
  const st_code = useRecoilValue(stateCode);
  const navigate = useNavigate();
  const location = useLocation();
  const [issubmit, setsubmit] = useState(false);
  const [msg, setMsg] = useState(null);
  const [emailval, setemail] = useState("");
  const [passwordval, setpassword] = useState("");
  const [emailError, setemailerror] = useState(false);
  const [passwordError, setpassworderror] = useState(false);
  const [val, setval] = useState(1);
  const [isname, setmyname] = useRecoilState(myname);
  const [loginstatus, setlogin] = useRecoilState(islogin);
  const [myimage,setmyimage] = useRecoilState(user_img);
  const formData = new FormData();
  formData.append("username", emailval);
  formData.append("password", passwordval);
  /*  useEffect(() => {
      const { msg } = location.state || {};
      setMsg(msg);
  
      // Remove the message after 20 seconds
      const timerId = setTimeout(() => {
        setMsg(null);
      }, 20000);
  
      // Clean up the timer when the component unmounts
      return () => clearTimeout(timerId);
    }, [location.state]); 

    if(loginstatus){
      navigate('/'+language+'/'+st_code+'/');
    }*/
  const { isLoading, isSuccess, status, error, data } = useQuery({
    queryKey: ["LoginForm", val],
    cacheTime: 0,
    queryFn: () => Login_Form("LoginForm", formData),
    enabled: issubmit,
  });
  useEffect(() => {
    if (status === "success") {
      if (data.data !== 0) {
        setlogin(true);
        setmyname(data.data.name);
        setmyimage(data.data.image)
        console.log(data.data);
        navigate(`/${language}/?msg=successfully+login`);
        //  navigate('/'+language+'/'+st_code, { state: { msg: { data: 'successfully login' } } });
      }
    }
  }, [loginstatus, status, isname]);

  function handleSubmit(event) {
    event.preventDefault();
    if (emailval === "") {
      setemailerror(true);
    } else {
      setemailerror(false);
    }
    if (passwordval === "") {
      setpassworderror(true);
    } else {
      setpassworderror(false);
    }
    if (emailval !== "" && passwordval !== "") {
      formData.set("username", emailval);
      formData.set("password", passwordval);
      setval(val + 1);
      setsubmit(true);
    }
  }

  const handleEmailchange = (event) => {
    setemail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setpassword(event.target.value);
  };

  return (
    <div className="container mt-4">
      {msg && (
        <div className="row justify-content-center p-4">
          {" "}
          <Alert variant="success">{msg.data}</Alert>
        </div>
      )}

      <div className="row justify-content-center p-4">
        <div
          className="col-md-6 col-sm-12 mx-auto p-3"
          style={{ border: "2px solid", backgroundColor: "#f2f6fc" }}
        >
          {status === "success" && data.data === 0 ? (
            <Alert variant="danger">{AppMenus[0].user_nt_aval}</Alert>
          ) : (
            ""
          )}
          <Form onSubmit={handleSubmit}>
            <Form.Group
              controlId="formBasicEmail"
              style={{ textAlign: "left" }}
            >
              <Form.Label>
                {AppMenus[0].email}/{AppMenus[0].mobile}
              </Form.Label>
              <Form.Control
                type="text"
                placeholder={AppMenus[0].user_name}
                autoFocus={true}
                value={emailval}
                onChange={handleEmailchange}
                isInvalid={emailError}
              />
            </Form.Group>

            <Form.Group
              controlId="formBasicPassword"
              style={{ textAlign: "left" }}
              className="mt-3"
            >
              <Form.Label>{AppMenus[0].password}</Form.Label>
              <Form.Control
                type="password"
                value={passwordval}
                placeholder={AppMenus[0].password}
                onChange={handlePasswordChange}
                isInvalid={passwordError}
              />
            </Form.Group>

            <div className="d-flex justify-content-between align-items-center">
            <div style={{color:'blue',cursor:'pointer'}} onClick={()=>navigate('/'+language+'/fg_pass')}>  {AppMenus[0].fgpass}</div>
            </div>
            {isLoading ? (
              <Spinner style={{ color: "#468094" }} />
            ) : (
              <Button
                style={{
                  backgroundColor: "#468094",
                  border: "1px solid #468094",
                }}
                type="submit"
              >
                {AppMenus[0].login}
              </Button>
            )}
          </Form>
          <div className="text-center mt-3">
            <span>{AppMenus[0].nuser} </span>
            <Link to={"/" + language + "/register"}>
              {AppMenus[0].register}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
