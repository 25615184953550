import { Container, Row, Col } from "react-bootstrap"

function Privacy()
{
    return(
        <Container>
            <Row>
            <Col  style={{textAlign:'justify', marginTop:'10px',boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',padding:'10px', }} sm={{span:'6',offset:'3'}}>

            <h5>Privacy Policy</h5>

<p><em>Effective Date: 02/09/2023</em></p>

<h6>Introduction</h6>
<p>
  Welcome to MP GKC, your trusted source for the latest updates on current affairs of Madhya Pradesh and India, along with daily quizzes. We value your trust and are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and safeguard your personal information when you use our mobile application.
</p>

<h6>Information We Collect</h6>
<p>
  <strong>User Registration:</strong> When you register for the MP GKC app, we may collect your email address and mobile phone number to create and verify your account. This information is used to provide you with personalized content and features.
</p>
<p>
  <strong>Usage Data:</strong> We may collect data about how you use the app, including the quizzes you take, the articles you read, and the content you engage with. This information helps us improve our services and tailor content to your preferences.
</p>

<h6>How We Use Your Information</h6>
<p>
  <strong>Personalization:</strong> We use your email address and mobile number to personalize your experience within the app, such as recommending quizzes and articles that match your interests.
</p>
<p>
  <strong>Communication:</strong> We may use your email address or mobile number to send you important updates, notifications, and announcements related to the app's content and features.
</p>
<p>
  <strong>Improvement:</strong> We analyze usage data to enhance the app's functionality, content, and user experience. This data is used for research, statistical analysis, and product development.
</p>

<h6>Data Security</h6>
<p>
  We take the security of your data seriously and employ industry-standard security measures to protect it from unauthorized access, disclosure, alteration, and destruction. We continuously monitor and update our security practices to ensure your information is safe.
</p>

<h6>Data Sharing</h6>
<p>
  We do not share your personal information with third parties without your consent, except in the following circumstances:
</p>
<p>
  <strong>Service Providers:</strong> We may share data with trusted third-party service providers who assist us in delivering the app's features and functionalities. These providers are bound by confidentiality agreements and are prohibited from using your data for any other purpose.
</p>
<p>
  <strong>Legal Compliance:</strong> We may disclose your information if required by law, to comply with legal processes, or to protect our rights, privacy, safety, or property.
</p>


<h6>Updates to this Privacy Policy</h6>
<p>
  We may periodically update this Privacy Policy to reflect changes in our practices and services. We will notify you of any significant changes by email or through app notifications.
</p>

<h6>Contact Us</h6>
<p>
  If you have any questions, concerns, or requests regarding this Privacy Policy or your personal data, please contact us at support@mpgkc.in.
</p>

<p>
  By using the MP GKC mobile app, you agree to the terms outlined in this Privacy Policy. Your continued use of the app signifies your acceptance of any updates or revisions to this policy.
</p>

<p>
  Thank you for choosing MP GKC. We appreciate your trust and are committed to providing you with valuable content while safeguarding your privacy.
</p>
                </Col>
            </Row>
        </Container>
    )
}
export default Privacy